import React from "react";
import { Container } from "react-bootstrap";

const Faqs = () => {
  return (
    <Container className="margin-left-right" style={{ marginBottom: "2rem" }}>
      <div className="mt-5 text-center">
        <h2 style={{ marginTop: "14rem", marginBottom: "2rem" }}>FAQ's</h2>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What’s Conscious State?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Conscious State is a conglomerate of scientists, doctors,
              innovators, designers and administrators from various backgrounds,
              instigated with an intention to empower students of nations so our
              students can learn through high masters/mentors who can educate
              them the right way and facilitate them to secure their careers, be
              it in the form of jobs/higher
              education/entrepreneurs/designers/innovators/artists/researchers
              and problem solvers. CST is intended to be one stop for total
              learning guiding the student from schooling through to the
              university until they become passionate individuals and powerful
              thinkers who could hold crucial positions in Nations building and
              address issues on world platforms.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Who is Conscious State?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              students/parents/mentors/scientists/artists/faculty/collaborations/projects/research
              labs/entrepreneurs. The people of CST are very unique for we do
              our things in a way they need to be done so it is important anyone
              who is willing to join know its people and how it works.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              What does CST constitute of?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              CST constitutes of 1.) School – Art of Thinking 2.) Academy - Art
              of Design 3.) Studio - Art of Expression
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              How does CST function?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              CST functions in 3 layers in its 3 segments, each of which equips
              our students with all capacities so they achieve technical and
              intellectual excellence, in a way that their learning translates
              into design and adds to their personality, plus equip them with
              essentials for securing their careers/clearing exams.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              What’s the idea of CST?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              The idea of the CST is quite simple. We want to remove the fear of
              the students about their careers by giving them career paths
              through their mentors and enable our students to invest their
              energies in delivering great work.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              What are the motives of CST?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              The motive of CST is to achieve self sufficiency and self
              awareness. CST is intended to be the 1.) One stop for total
              learning 2.) To create a platform where the students can get a
              platform to get opportunities to secure their careers 3.) To be a
              Global hub for intellectual connects. 4.) To create a place that
              shall conceive great innovations and artistic expressions.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              How do they intend to achieve it?
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              We have a way of teaching our students to equip them with
              capacities that are required at both technical and intellectual
              level in a way we build their resumes from day 1. We have
              collaborations with both national and international
              organizations/research labs/studios/entrepreneurs, which are
              expanding every day across several departments. CST is equipped
              with school/academy/studio of its own which is currently working
              on various innovative projects to achieve the required.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEight">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              What’s the School?
            </button>
          </h2>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              School is where our students learn the ART OF THINKING. It
              consists of classes 9th to 12th who shall be finishing their
              CBSE/IGCSE syllabus according to the learning methodology of the
              CST so they are equipped with art of READING, WRITING, and
              LISTENNG, SPEAKING and TEACHING, THINKING We are considering
              taking 7th and 8th which is yet to be finalized.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingNine">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              What do they learn in the School?
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              We complete the syllabus in a unique way so they know the
              technique that enables them to clear exams on the back of their
              hand plus equips them with problem solving abilities and
              logical/creative thinking. Here they learn by doing.The learning
              happens by following the four major beliefs of CST Inspire
              Stimulate Equip Make Responsible
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
            >
              How does the school function?
            </button>
          </h2>
          <div
            id="collapseTen"
            class="accordion-collapse collapse"
            aria-labelledby="headingTen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              The school has a programme of its own where students shall be
              involved in projects of their interest after having completed the
              syllabus. The project shall enable them to understand how the
              concepts covered in their syllabus apply in real time.Their
              Schooling shall equip them with the thought process so they can
              perform the following on the back of their hand. Think Organise
              Structure Execute
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEleven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
            >
              What’s the Strength of the school?
            </button>
          </h2>
          <div
            id="collapseEleven"
            class="accordion-collapse collapse"
            aria-labelledby="headingEleven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              The overall strength of the school is 40. We take only 40 students
              from all over the globe. These 40 could spread across 9th -12th.
              The intention is that these students shall become the masters of
              their trades in the relevant departments of their interest. They
              shall know every technique/ins and outs of the CST and scientific
              and artistic methodology of the legends. We have currently issues
              20 invitations out. Interested students can express their interest
              in joining the school.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwelve">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
            >
              What are various platforms to study at CST?
            </button>
          </h2>
          <div
            id="collapseTwelve"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwelve"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              1.) The selected candidates shall be studying in the school by
              presenting themselves physically in the school and completing
              their regular syllabus and the project. 2.) Day scholars are
              accepted in exceptional cases provided they commit to the fact of
              the confidentiality of the projects, R&D happening at CST. 3.)
              Online platforms are being opened for overseas students/students
              from distance/ who could access the CST class recordings, plus
              meet us online. However the students have to present themselves in
              order to complete their project. Fee structure for the online
              students shall be different to that of boarders 4.) We exchange
              students from overseas to perform knowledge transfer as well as
              Tech transfer.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThirteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirteen"
              aria-expanded="false"
              aria-controls="collapseThirteen"
            >
              How to get admitted into CST?
            </button>
          </h2>
          <div
            id="collapseThirteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingThirteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              A student interested in joining has to express their intent in the
              form of an Expression of Interest (EOI) detailing their persona
              and why they are willing to join us and where their interest lies.
              We take references/EOI into consideration to shortlist the
              students so we could invite them for a personal interview which
              shall be a dialogue between the faculty, student and the parents.
              schoolofconsciousstate@gmail.com
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFourteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFourteen"
              aria-expanded="false"
              aria-controls="collapseFourteen"
            >
              What’s the Selection process and criteria?
            </button>
          </h2>
          <div
            id="collapseFourteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingFourteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              We wanted our students to become masters of their trade in
              different departments of different mentors. We have dedicated
              number of students allocated to different departments, although we
              are open to accept phenomenal out of the box thinkers in the
              school. We select purely based on their interview and expression
              of interest document a student writes detailing about themselves.
              schoolofconsciousstate@gmail.com
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFifteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFifteen"
              aria-expanded="false"
              aria-controls="collapseFifteen"
            >
              What’s the Academy?
            </button>
          </h2>
          <div
            id="collapseFifteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingFifteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Academy is the house of projects where they master the ART OF
              DESIGN which shall eventually translate into publications/copy
              rights/ip.etc. Students shall be working on a project in the area
              of their interest here in the academy so they know what goes into
              designing and delivering great innovations and artistic
              expressions by working with the mentors in the department of their
              interest. The project shall enable them to understand the physical
              forms of the scientific concepts. This project shall translate
              into physical forms like copyright, references, IP, contacts,
              research papers, white papers, validations, entrepreneurship..Etc
              which shall help in resume building in order to secure their
              careers
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSixteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSixteen"
              aria-expanded="false"
              aria-controls="collapseSixteen"
            >
              Which students can join Academy?
            </button>
          </h2>
          <div
            id="collapseSixteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingSixteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Students ranging from school to university can join academy.
              Students range from higher secondary school to post doc students
              (9th - 12th, Bsc, Msc, Phd, Post Doc) from different backgrounds
              can join academy.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeventeen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeventeen"
              aria-expanded="false"
              aria-controls="collapseSeventeen"
            >
              How does the academy function?
            </button>
          </h2>
          <div
            id="collapseSeventeen"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeventeen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Academy teaches them how to structure and organize their learning
              and practically apply it so they can relate their syllabus and
              respective sciences to real time scenarios. Academy introduces
              most recent advancements in the area of science and arts and links
              it to the syllabus. Students learn, work and document the methods
              and observations in the academy to publish.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEightteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEightteen"
              aria-expanded="false"
              aria-controls="collapseEightteen"
            >
              What do they teach in the academy?
            </button>
          </h2>
          <div
            id="collapseEightteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingEightteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Academy teaches a problem solving, writing documents, designing,
              leading a project, experimentation, research methodologies,
              validating methods, exploring opportunities and getting
              opportunities, entrepreneurship, innovating
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingNineteen">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNineteen"
              aria-expanded="false"
              aria-controls="collapseNineteen"
            >
              What’s the studio?
            </button>
          </h2>
          <div
            id="collapseNineteen"
            class="accordion-collapse collapse"
            aria-labelledby="headingNineteen"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Studio is where they learn ART OF EXPRESSION communicating their
              wisdom through various creative forms, storytelling. Studio is a
              platform where students get a voice. Here is where they interview,
              enquire, express; sit in dialogue with intellects, present their
              talents.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwenty">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwenty"
              aria-expanded="false"
              aria-controls="collapseTwenty"
            >
              How many departments are there in CST?
            </button>
          </h2>
          <div
            id="collapseTwenty"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwenty"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              CST is divided into faculty of arts and science. Faculty of
              science involves Agriculture, Architecture, Bio Fuels, Medicine,
              Energy, Engineering, Environment, Artificial Intelligence, Aero
              Space, and Rocketry in the faculty of Science. Faculty of arts is
              significant in The CST and students shall be meeting artistic
              fraternity from Music, Dance, Literature, Drama, Singing, Story
              Telling, Painting and Sculpture.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyOne"
              aria-expanded="false"
              aria-controls="collapseTwentyOne"
            >
              What are the Projects intended for?
            </button>
          </h2>
          <div
            id="collapseTwentyOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              There are two kinds of projects Research projects and
              entrepreneurial projects. Mentors of different departments design
              different projects in the area of their expertise which have
              significant relation to the CBSE syllabus of the students, social
              impact, real time scenarios, problem solving, and practical
              applications. The projects are intended to get the students
              practical exposure, experimentation, designing, innovating,
              research, entrepreneurship. Students shall be initially working on
              these pilot projects which shall eventually translate into
              entrepreneurial projects for their careers.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyTwo"
              aria-expanded="false"
              aria-controls="collapseTwentyTwo"
            >
              What are the research projects?
            </button>
          </h2>
          <div
            id="collapseTwentyTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Research projects are the projects designed by the mentors to
              teach research methodology and applications of science in real
              time there by help in the advancements of science.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyThree"
              aria-expanded="false"
              aria-controls="collapseTwentyThree"
            >
              What are the immediate Entrepreneurial projects?
            </button>
          </h2>
          <div
            id="collapseTwentyThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Entrepreneurs who are interested in ethical model of business
              could come and invest in the large scale projects which our
              students do it in the small scale. We are building vertical farms
              in the faculty of science and Great Library from the faculty of
              Arts currently. Entreprenurs who are interested can express their
              interest to our official email address
              schoolofconsciousstate@gmail.com
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyFour"
              aria-expanded="false"
              aria-controls="collapseTwentyFour"
            >
              What’s the role of the mentors?
            </button>
          </h2>
          <div
            id="collapseTwentyFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyFour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Mentors guide the students to achieve technical excellence,
              intellectual excellence, facilitate to get careers, contacts,
              opportunities, purpose in life and are crucial in inspiring,
              stimulating, creating and making the students responsible.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyFive"
              aria-expanded="false"
              aria-controls="collapseTwentyFive"
            >
              What are the collaborations for?
            </button>
          </h2>
          <div
            id="collapseTwentyFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyFive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              Our collaborations are critical as we work together for a great
              vision to serve the mankind. Our collaborations are unending and
              only more people are going to come into CST to make it a global
              hub of intellectual connects and serve as learners, student’s
              platform for learning, pursuing their careers and to deliver Great
              Innovations and Artistic Expressions. We collaborate in the best
              interest of our students and to work in as an interdisciplinary
              unit. Our collaborations open the skies to work for the common
              good.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentySix">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentySix"
              aria-expanded="false"
              aria-controls="collapseTwentySix"
            >
              What’s the role of the research projects?
            </button>
          </h2>
          <div
            id="collapseTwentySix"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentySix"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              It is here entrepreneurs shall have an opportunity to take part in
              building the Nation’s economy as well as theirs, so they could
              generate income and employment.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentySeven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentySeven"
              aria-expanded="false"
              aria-controls="collapseTwentySeven"
            >
              How does CST help for securing careers for the students?
            </button>
          </h2>
          <div
            id="collapseTwentySeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentySeven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              CST provides mentors, references, builds the resumes, provides
              clear career path, guidance, entrepreneurial platforms,
              entrepreneurial skill, copyrights, experience letters everything
              that shall help students in securing their careers
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyEight">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyEight"
              aria-expanded="false"
              aria-controls="collapseTwentyEight"
            >
              What are entrepreneurial projects?
            </button>
          </h2>
          <div
            id="collapseTwentyEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyEight"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              It is here entrepreneurs shall have an opportunity to take part in
              building the Nation’s economy as well as theirs, so they could
              generate income and employment.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwentyNine">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwentyNine"
              aria-expanded="false"
              aria-controls="collapseTwentyNine"
            >
              What certification do they get?
            </button>
          </h2>
          <div
            id="collapseTwentyNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwentyNine"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              They get certified by CBSE/IGCSE board.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThirty">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirty"
              aria-expanded="false"
              aria-controls="collapseThirty"
            >
              Where is CST based?
            </button>
          </h2>
          <div
            id="collapseThirty"
            class="accordion-collapse collapse"
            aria-labelledby="headingThirty"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              The corporate registered office is in Hyderabad and the research
              base is in Chennai. We are also opening our office in London, UK
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThirtyOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirtyOne"
              aria-expanded="false"
              aria-controls="collapseThirtyOne"
            >
              Has the CST affiliated?
            </button>
          </h2>
          <div
            id="collapseThirtyOne"
            class="accordion-collapse collapse"
            aria-labelledby="headingThirtyOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              CST is in collaboration with Geethanjali Global Schools, Kompally,
              Hyderabad.
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Faqs;
