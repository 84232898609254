import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { MdLocationOn, MdOutlineKeyboardArrowRight } from "react-icons/md";
import Studi0Banner from "../components/Studi0Banner";
import MapStudio from "../components/MapStudio";
import "./OurCenters.css";

const OurCenters = () => {
  const Footprint = [
    {
      id: 0,
      lable: "EUROPEAN WELLNESS ACADEMY & SHOWCASE",
      place: "Kota Kinabalu, Malaysia",
      address:
        " Unit 67 & 68, Block K, Alamesra Plaza Utama, Jalan Sulaman, 88450 Kota Kinabalu, Sabah",
      img: "/images/ew-malaysia.jpg",
      Website: [],
      Headers: "",
      describtion:
        "In September 2016, a Training Facility was opened in Kota Kinabalu, Malaysia, where doctors, nursing staff, technicians and researchers acquire, test and train to use state of the art technology and methodologies. This facility has welcomed more than a hundred guests since its opening, offering interested parties including licensed healthcare practitioners CPD certified training of European Wellness technologies and treatment methods.",
    },
    {
      id: 1,
      lable: "ORTHOBIOTIKI BY EUROPEAN WELLNESS",
      place: "Athens, Greece",
      address: "5, Sorou 3, Marousi 151 25, Greece",
      img: "/images/orthobiotiki.jpg",
      Website: [],
      Headers: "",
      describtion:
        "Orthobiotics is one of the Unique Standard Polyclinics of Preventive and Regenerative Medicine with a leading role in the field of prevention, remaining faithful to its goals, offering high quality programs and health services and wanting to further improve its services, both internally and collaboratively. Orthobiotics is constantly renewing its partnerships with the main goal of partnering for a complete health system and is close to man, at all stages of his life.",
    },
    {
      id: 2,
      lable: "ZOE BIOREGENERATIVE WELLNESS CLINIC BY EUROPEAN WELLNESS",
      place: "Santorini, Greece",
      address: "",
      img: "/images/zoe-clinic.jpg",
      Website: ["Website – https://zoeclinics.com/"],
      Headers:
        "The Latest 6-Star European Wellness Center In Europe, Located At Picturesque Santorini, One Of The Most Beautiful Places In The World!",
      describtion:
        "Zoe Bio Regenerative Wellness Clinic is a distinguished legacy borne from its esteemed founders, the European Wellness Biomedical Group and the ABH Medical Group. Drawing upon their vast reservoir of advanced medical knowledge and substantial global footprint, ZOE has successfully established an ultra-modern medical wellness center, nestled in the heart of Santorini, Greece – one of the world’s most frequented destinations. The clinic not only boasts advanced medical proficiency, but also provides unique, proprietary treatment protocols. This innovative approach, coupled with the latest in technology and medical equipment, sets the clinic apart in the realm of regenerative wellness.",
    },
    {
      id: 3,
      lable: "SWISS WELLNESS CENTER",
      place: "Lugano, Switzerland",
      address: "via Maggio 1c,  CH-6900 Lugano, Switzerland",
      img: "/images/swiss-wellnes-center.jpg",
      Website: [],
      Headers: "",
      describtion:
        "Swiss Wellness Center (SWC) which began operating in 2015 is a wellness centre in partnership with European Wellness Group. Centrally located in the Italian-speaking city of Lugano in Switzerland’s south-east, SWC offers an in-house dental clinic and a complete range of biomedical medicine therapies. European Wellness’s physicians and partners worldwide consult with SWC for their expertise and insight in biological disorders through dental conditions and leadership in Swiss Cellular Therapy.",
    },
    {
      id: 4,
      lable: "GERMAN MEDICAL CENTER",
      place: "Frankfurt, Germany",
      address: "Vilbeler Landstraße 45B, 60388 Frankfurt am Main, Germany",
      img: "/images/home-slider17.jpg",
      Website: [],
      Headers: "",
      describtion:
        "In Germany, European Wellness™ operates as the German Medical Center through a partnership with the Siebenhüner practice, located in the centre of the Advanced Medical Clinic in Frankfurt. This clinic has been practicing holistic integrative medicine for 35 years.",
    },
    {
      id: 5,
      lable: "EW VILLA MEDICA GERMANY PREMIER CENTER",
      place: "Edenkoben, Germany",
      address: "",
      img: "/images/EWVM.jpg",
      Website: [],
      Headers: "",
      describtion:
        "Villa Medica is a premium destination clinic and licensed hospital establishment in Germany with an all-encompassing facilities under one roof. Its professional medical team, with more than 50 years of experience, provides patients with round the clock medical supervision within a relaxing environment, and bio molecular therapy is injected in the same facility. A team of experienced doctors is available at Villa Medica for various regenerative treatments.",
    },
    {
      id: 6,
      lable: "EW VILLA MEDICA BANGKOK PREMIER CENTER",
      place: "Bangkok, Thailand",
      address:
        "InterContinental Hotel, Unit M01, 3rd Floor, 973 Phloen Chit Rd, Lumphini, Pathum Wan District, Bangkok 10330, Thailand",
      img: "/images/villa-medica-th.jpg",
      Website: [],
      Headers: "",
      describtion:
        "EW Villa Medica Bangkok has been opened since 2015. It offers services of holistic medicine by holistic healthcare, not only specific remedies for health but also overall healthcare for fitness, diseases prevention, and anti-aging. With a luxurious, clear, and well-organized design, the clinic provides you a feeling of privacy as if you were home along with excellent quality services serving comfort and full treatments.",
    },
    {
      id: 7,
      lable: "EW VILLA MEDICA BANGLADESH PREMIER CENTER",
      place: "Dhaka, Bangladesh",
      address:
        "Navana Newbury Place, 4/1/A (5 Floor), Sobhanbag, Mirpur Road, Sher-E-Bangla Nagar, Dhaka-1207, BANGLADESH",
      img: "/images/villa-medica-bangladesh.jpg",
      Website: [],
      Headers: "",
      describtion:
        "At EW VILLA MEDICA BANGLADESH, we strive to revitalize and rejuvenate you by our personalized and premium regenerative solutions through integrating cell therapies, biomedical diagnostic, therapeutic devices and advanced health programs. We use innovative technologies, holistic methods and revolutionary knowhow to reverse age, redefine youth, renew illness and reinvent the quality of your life. We use innovative technologies, holistic methods and revolutionary know how to reverse age, redefine youth, renew illness and reinvent the quality of your life.",
    },
    {
      id: 8,
      lable: "EW VILLA MEDICA MANILA PREMIER CENTER",
      place: "Philippines",
      address:
        "2F Garden Wing, Newport Mall, Newport World Resorts, Pasay City, Philippines",
      img: "/images/ewvm-philippines-03.jpg",
      Website: [],
      Headers: "",
      describtion:
        "Located at the Garden Wing of Newport Mall at Resorts World Manila, this new center for holistic wellness, regenerative medicine, and rejuvenation specialises in delivering premium regenerative solutions through integrating cellular therapies, biomedical diagnostic and therapeutic devices with advanced health programs.",
    },
    {
      id: 9,
      lable: "EUROPEAN WELLNESS HOSPITAL",
      place: "Ho Chi Minh, Vietnam",
      address:
        "70-72 Nam Ky Khoi Nghia, Nguyen Thai Binh Ward, District 1, Ho Chi Minh City, Vietnam",
      img: "/images/ewh-vietnam.jpg",
      Website: [
        "Website – https://ewh.vn/",
        "FB – https://www.facebook.com/europeanwellness.ewh",
        "IG – https://www.instagram.com/EuropeanWellnessHospitalvn/",
        "YouTube – https://www.youtube.com/@EuropeanWellnessHospital",
        "TikTok – https://www.tiktok.com/@europeanwellnesshospital",
      ],
      Headers: "",
      describtion:
        "Located in the heart of District 1, Ho Chi Minh City, European Wellness Hospital (EWH) is a center providing comprehensive health care services based on the integration of cell therapies and regenerative medicine achievements. With modern facilities and a luxurious and fully equipped clinic, EWH focuses on creating a comfortable and private experience for its clients to make them feel right at home. A team of specialized doctors with more than 20 years of experience in the application of cell therapy in Vietnam brings a personalized treatment approach, helping each client reverse aging, regain youthfulness, proactively control long-term diseases, and holistically improve their overall health and quality of life.",
    },
    {
      id: 10,
      lable: "EUROPEAN WELLNESS RETREAT",
      place: "Kuala Lumpur, Malaysia",
      address: "The Mines, Kuala Lumpur, Malaysia",
      img: "/images/ghhs-wellness-center.jpg",
      Website: [],
      Headers: "",
      describtion: "",
    },
    {
      id: 11,
      lable: "EUROPEAN WELLNESS RETREAT",
      place: "Tagaytay, Philippines",
      address:
        "Buenavista Hills Road, Barangay, Tolentino West, Tagaytay Philippines",
      img: "/images/ewr-anya.jpg",
      Website: [],
      Headers: "",
      describtion:
        "Anya Wellness blooms through the partnership between European Wellness Retreat™ and Anya Resort Tagaytay.Luxury, comfort, and well-being meet in an exclusive sanctuary sitting atop a ridge between two mountains, resulting in the ultimate wellness destination that features the beautiful Tagaytay landscape, lavish and stylish accommodations, immaculate service, delectable healthy dishes, effective medical treatments, and precise, state-of-the-art diagnostic and treatment tools.Brought together by the opportunity to revolutionize a flourishing industry and the desire to make evidence-based medical wellness programs to more people, European Wellness Retreat and Anya Resort look forward to guiding guests in their health transformation. By visiting Niyama Wellness Center, Anya’s own wellness facility, guests will have exclusive access to Biological Wellness Consultation, Bio Med Scan, Skin Health Analyzer, specialized IV Therapy, and other treatments and machines only offered through European partners.",
    },
    {
      id: 12,
      lable: "EW SANORELL PHARMACEUTICAL PLANT",
      place: "Baiersbronn, Germany",
      address: " Rechtmurgstraße 27, 72270 Baiersbronn, Germany.",
      img: "/images/ew-sanorell-pharma.jpg",
      Website: [],
      Headers: "",
      describtion: "",
    },
  ];
  
  const [centerData, setCenterData] = useState(Footprint[0]);

  const handleTab = (i) => {
    const data = Footprint.filter((each) => each.id === i);
    setCenterData(data[0]);
  };

  console.log(centerData, "centerData");

  return (
    <div className="our-centers">
      <Studi0Banner txt="Our Centers" img='bannerImage' />
      <Container>
        <Row>
          <Col md={6}>
            <div className="our_centers_heading">Our Centers</div>
            <div className="our_centers_small_text">
              Our Healthcare Center Are Available All Around The World
            </div>
            <div className="para">
              Our European Wellness therapies are offered at various certified
              centres around the world. You can enjoy the locality of the area
              while being immersed in European Wellness’ integrative treatments.
              Experience personalised care by our certified physicians and enjoy
              the results of quality and safe therapies offered at our various
              centres
            </div>
            <div className="vcex-heading">
              "Our Setting Is Local, Our Treatments Holistic."
            </div>
          </Col>
          <Col md={6} className="wpb_column vc_column_container">
            <MapStudio />
          </Col>
        </Row>
      </Container>
      <div className="vc_custom">
        <Container>
          <Row className="our_centers_heading" style={{ color: "#fff" }}>
            Our Global Footprint
          </Row>
          <Row className="our_centers_small_text" style={{ color: "#fff" }}>
            Worldwide Healthcare
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={6}>
            {Footprint.map((ach, i) => (
              <div
                className={centerData.id === i ? "vc_tta_actv" : "vc_tta-tab"}
                key={i}
                onClick={() => handleTab(i)}
              >
                <MdLocationOn size={18} /> {ach.lable} - {ach.place}
              </div>
            ))}
          </Col>
          {Object.keys(centerData).length > 0 && (
            <Col md={6}>
              <img src={centerData.img} alt="" className="vc_tta-Image" />
              <div className="vc_label">{centerData.lable}</div>
              <div className="vcex-place">{centerData.place}</div>
              <div className="wpb_wrapper">
                <strong>Address: </strong> {centerData.address}
              </div>
              <div className="para mb-4">{centerData.describtion}</div>
              {centerData.Website.length > 0 && (
                <div>
                  <strong>Link</strong>
                  {centerData.Website.map((term, i) => (
                    <div className="wpb_wrapper pb-2">{term}</div>
                  ))}
                </div>
              )}

              <NavLink to="" className="vcx_button">
                Contact Us
                <MdOutlineKeyboardArrowRight />
              </NavLink>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default OurCenters;
