import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Menters = ({data}) => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 1192 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1191, min: 768 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
        },
      };

  return (
    <Container  className="mt-3">
        
        <Carousel
          arrows={true}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
        >
          {data.length > 0 && data.map((item,i) => 
            <Row key={i}>
            <h2 className="main-titles">{item.title}</h2>
            <Col md={6} className=''>
            <img
            src={item.img}
            alt=""
            className="menterimage"
          />
           {item.data.length > 0 && 
                <div>
                    <div className="menter-subtitle">Designations</div>
                    {item.data.map((each,i) =>
                    <div key={i}>{each}</div>)}
                </div>
                }
                
          </Col>
          <Col md={6}>
          {item.footPrints !== "" &&
            <div>
                <div className="menter-subtitle">The Emotional Foot Prints From The Founder-In His Words About His Mentor</div> 
                <p>{item.footPrints}</p></div> 
                   }
                   {item.content !== "" &&    
                    <div className='cotation'>{item.content}</div>}
          {item.Description !== "" && 
            <div>
                <div className="menter-subtitle">Description</div>
                <p>{item.Description}</p>
            </div>
                }
            {item.SocialLinks.length > 0 && 
             <div>
             <div className="menter-subtitle">Social Media Links</div>
             {item.SocialLinks.map((each,i) =>
             <div key={i}>{each}</div>)}
         </div>
                }
          </Col>
            </Row>
            )}
           
          
        </Carousel>
       
      
    </Container>
  )
}

export default Menters
