import React from "react";
import { Container} from "react-bootstrap";

const School1 = () => {
  return (
    <Container className="">
      <img
        src="/images/IMG-20230410-WA0021.jpg" alt=''
        style={{
          display: "block",
          height: "100%",
          margin: "auto",
          width: "100%",
        }}
      />
      <div>
        <h2 className="main-titles my-5">Studying at Conscious State</h2>
      </div>
      <div className="row">
        <h2 className="my-2">School</h2>
        <p>
          Learning should unshackle a soul from all insecurities and unleash the
          liberated spirit to endanger the insanity; such learning shall only
          happen in a pious environment through enlightened masters who are in
          abundance in love
        </p>
        <h4 className="my-4">
          There are three platforms currently available to study at CST
        </h4>
        <div
          data-aos="fade-up"
          className="col-lg-3 col-md-4 my-2 my-sm-0 col-sm-6"
        >
          <div
            className="col h-100 my-card"
            
          >
            <p>
              1.) The selected candidates shall be studying in the school by
              presenting themselves physically in the school and completing
              their regular syllabus and the project.
            </p>
          </div>
        </div>
        <div
          data-aos="zoom-out-left" data-aos-duration="3000"
          className="col-lg-3 col-md-4 my-2 my-sm-0 col-sm-6"
        >
          <div
            className="col h-100 my-card"
            
          >
            <p>
              2.) Day scholars are accepted in exceptional cases provided they
              commit to the fact of the confidentiality of the projects, R&D
              happening at CST.
            </p>
          </div>
        </div>

        <div
          data-aos="fade-up"
          className="col-lg-3 col-md-4 col-sm-6 my-2 my-sm-0 mt-sm-4 mt-md-0"
        >
          <div
            className="col h-100 my-card"
           
          >
            <p>
              3.) Online platforms are being opened for overseas
              students/students from distance/ who could access the CST class
              recordings, plus meet us online. However the students have to
              present themselves in order to complete their project. Fee
              structure for the online students shall be different to that of
              boarders.
            </p>
          </div>
        </div>
        <div
          data-aos="zoom-out-left"
          className="col-lg-3 col-md-4 col-sm-6 my-2 my-sm-0 mt-sm-4 mt-md-4 mt-lg-0"
        >
          <div
            className="col h-100 my-card"
            
          >
            <p>
              4.) We exchange students from overseas to perform knowledge
              transfer as well as Tech transfer.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <h4 className="main-titles my-4">Students Requirements</h4>
        <p className="">
          Every student shall have their health checked and the parent is
          required to fill in a form detailing any dietary
          requirements/hypersensitive reactions so we can make sure that no on
          toward reactions can happen.
        </p>
        <h4>Student shall have to have the following:</h4>
        <ol className="mx-4">
          <li>Health Insurance</li>
          <li>Bank account</li>
          <li>Learning’s diary</li>
          <li>Academic file</li>
          <li>Contacts diary</li>
          <li>CST email account</li>
          <li>Lab coat</li>
          <li>Their signature musical instruments</li>
          <li>
            We could entertain their pets (As long as the student take care of
            themselves)
          </li>
        </ol>
        <div>
          <h4>Empowering at CST – (Happiness, Health and Learning Index)</h4>
          <p>A lot of thought goes into every detail at CST</p>
        </div>
        <div>
          <div>
            <span>
              <b>BODY</b>
            </span>
            <span className="mx-4">
              <b>MIND</b>
            </span>
            <span>
              <b>SOUL</b>
            </span>
          </div>
          <p>
            Food- It shall be vegetarian food and it shall be both traditionally
            and scientifically tailored.
          </p>
        </div>
        <div data-aos="flip-right" data-aos-duration="3000" className="col-12 col-md-4 my-3 my-lg-0">
          <div
            className="col h-100 my-card"       
          >
            <h2>Health-</h2>
            <p>
              <i>“Hydrate, Energize, Window of fixation”</i>
            </p>
            <p>
              We take the stats of initial health index and we shall do
              everything in our power so that its in par with the standards. We
              would in major have a natural healing/innate systems engineer
              itself rather than relying on medicines.
            </p>
            <p>
              We provide the right environment/care by giving fair amount of
              observation to the health index/life style/habits/mannerisms so we
              can equip them with the awareness of their health and have them
              respect their state of well being by fixing the in appropriate
              observations.
            </p>
            <p>
              We shall make our students aware of the cellular autophagy so they
              can be in control of their well being.
            </p>
          </div>
        </div>
        <div data-aos="flip-left" className="col-12 col-md-4 my-3 my-lg-0">
          <div
            className="col h-100 my-card"
          >
            <h2>Mind</h2>
            <p>
              <i>
                “Observe and Remain Still- calmness has a way of understanding
                and interpreting”
              </i>
            </p>
            <p>
              Academics at CST are designed in a way that it shall serve as a
              multipurpose facility for learning. We have our own methods as to
              how we enable our students to become original and fearless
              thinkers.
            </p>
            <p>
              Academics at CST are designed in a way that it shall serve as a
              multipurpose facility for learning. We have our own methods as to
              how we enable our students to become original and fearless
              thinkers.
            </p>
            <p>
              Having completed their learning’s, our students shall be
              acquainted and associated with the physical forms of their
              learning be it in the form of the design, mega structure,
              intellect, document so they see their learning applied in real
              time which shall be easy to relate and ponder on the future works
              of their signature.
            </p>
          </div>
        </div>
        <div
          data-aos="flip-right"
          className="col-12 col-md-4 my-3 my-lg-0"
        >
          <div
            className="col h-100 my-card"
          >
            <h2>Soul</h2>
            <p>
              <i>
                “Being sensitive to life - It’s a lightened soul when one has
                the total understanding”.
              </i>
            </p>
            <p>
              Enabling one to understand as to how to deliver on the back of
              their hand by imparting technical and intellectual excellence is
              critical. Learning it in a relaxed environment in close
              association to nature enables one to feel and witness the life.
            </p>
            <p>
              At CST, we relate learning science to the life forms so we can
              relate to nature rather than placing useless efforts in outwitting
              it.
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col">
          <h4 className="main-titles">
            Parents Form - Please be very honest in filling this form
          </h4>
          <h6>Impression about the Student –</h6>

          <form>
            <div class="mb-3">
              <label for="character" class="form-label">
                Character
              </label>
              <input type="text" class="form-control" id="character" />
            </div>
            <div class="mb-3">
              <label for="academics" class="form-label">
                Academics
              </label>
              <input type="text" class="form-control" id="academics" />
            </div>
            <div class="mb-3">
              <label for="artistic" class="form-label">
                Artistic
              </label>
              <input type="text" class="form-control" id="artistic" />
            </div>
            <div class="mb-3">
              <label for="intellectual" class="form-label">
                Intellectual
              </label>
              <input type="text" class="form-control" id="intellectual" />
            </div>
            <div class="mb-3">
              <label for="health" class="form-label">
                Health
              </label>
              <input type="text" class="form-control" id="health" />
            </div>
            <div class="mb-3">
              <label for="virtues" class="form-label">
                Virtues
              </label>
              <input type="text" class="form-control" id="virtues" />
            </div>
            <h2 className="main-titles">DIFFICULTIES</h2>
            <div class="mb-3">
              <label for="allergies" class="form-label">
                Allergies
              </label>
              <input type="text" class="form-control" id="allergies" />
            </div>
            <div class="mb-3">
              <label for="dietary" class="form-label">
                Dietary Requirements
              </label>
              <input type="text" class="form-control" id="dietary" />
            </div>
            <div class="mb-3">
              <label for="reports" class="form-label">
                Health Check reports
              </label>
              <input type="text" class="form-control" id="reports" />
            </div>
            {/* <button type="submit" class="btn btn-primary">Submit</button> */}
          </form>
        </div>
        <h4>Unique Observations about the Candidate</h4>
      </div>
    </Container>
  );
};

export default School1;
