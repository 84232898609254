import React from "react";
import { NavLink } from "react-router-dom";

const SubHeader = () => {
  return (
    <div className="subHeader">
      <NavLink to="/entrepreneurs" className="sublogo">
        Entrepreneurs
      </NavLink>
      <NavLink to="/" className="sublogo">
        Students
      </NavLink>
      <NavLink to="/" className="sublogo">
        Scientists
      </NavLink>
      <NavLink to="/" className="sublogo">
        Artists
      </NavLink>
      <NavLink to="/investors" className="sublogo">
        Investors
      </NavLink>
    </div>
  );
};

export default SubHeader;
