import React from 'react'

const Studi0Banner = ({txt, img}) => {
  return (
    <div className={`Image ${img}`} >
        <div className="our_centers_text">{txt}</div>
      </div>
  )
}

export default Studi0Banner
