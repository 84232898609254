import React from "react";
import About from "./view/About";
import State from "./view/State";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";

import CardsComponent from "./components/CardsComponent";
import StudentLogin from "./view/StudentLogin";
import Menters from './view/Menters'

const mentorsArr = [
  {
    id: 2,
    name: "Prof. M.S. Swaminathan",
    appearance: "Father of the Green Revolution in India",
    image: "/images/55.png",
  },
  {
    id: 1,
    name: "Sir B.V.Naidu",
    appearance: "Father of the Silicon Valley of Bangalore",
    image: "/images/66.png",
  },
  {
    id: 6,
    name: "Prof. Dato Sri Dr. Mike K.S. Chan",
    appearance: "Researcher and educator",
    image: "/images/chan.png",
  },
 
  {
    id: 3,
    name: "Bunker Roy",
    appearance: "Founder of Barefoot College",
    image: "/images/44.png",
  },
  {
    id: 4,
    name: "Thota Tharani",
    appearance: "Renowned Indian artist and production designer",
    image: "/images/22.png",
  },
  // {
  //   id: 5,
  //   name: "Dr Ali",
  //   appearance: "Advocate for happiness and health",
  //   image: "/images/33.png",
  // },
 
];

const Conservation = () => {
  
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1192 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1191, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  

  const data = {
    title: "The Conscious State",
    data: "The Conscious State is ideated by Prof Dr Ravi Tej Allam with an intention to create a place that acts as a one stop solution for Education/Research and Entrepreneurship. It started as a global conglomerate of the 1% powerful brains responsible for innovations and artistic expressions that were creating a phenomenal impact, by acting as the solution providers for the world’s problem statements. It’s an accumulation of scientists/artists and intellectuals from agriculture to aerospace research. Fueling to his idea, his eminent mentors has Sir B.V.Naidu and Prof. M.S.Swaminathan, Bunker Roy and many more stalwarts has equipped the idea with the necessary infrastructure to shape it as a powerful place for student transformation who could shape the future of the Nation. In course of its journey CST has become an asset for/of the students, making it more of a global hub centric to serious/focused student power, as well as intellectual power that could generate wealth for the Nation by floating viable entrepreneurial platforms with solutions that adds to the quality life style of the people of the Republic. CST students/intellects/investors and entrepreneurs function across several verticals in both faculty of sciences as well as arts. They predominantly focus on education/research/innovations and entrepreneurships.",
  };

  const data1 = {
    title: "Forces Fueling The Thought ",
    data: "I believe, the most difficult thing a young man/woman faces is getting a Great Guru. As they say that there were no bad students, there were only bad teachers. But if there is one right teacher world earns a powerful and a dangerous man/woman who could take the sorrow of an unfinished song to the grave. I am blessed and humbled to have some phenomenal mentors all along the way, who has been the forces fueling the idea and navigating us towards righteousness teaching me the art of war. I would rather want all the students of the CST to experience the journey with the powerful 1% by entering into their secret chambers so they become aware how men and women are manufactured in the God’s workshop. THE GREAT LIBRARY franchise from the CST Student’s global media is one such initiative that serves as an asset to emotionally trigger the sacred flames of Truth in the young men/women."
  }

  const naiduData =[ 
    {
      title: "Prof.M.S.Swaminathan ",
      img: '/images/WhatsApp Image 2023-06-18 at 8.08.53 PM.jpeg',
      Description: '',
      Photos: '',
      SocialLinks : [],
      content: " “The most powerful country in the world is going to be the country with the grains not guns”- ", 
      data: ["Chairman of MSSRF",
      "Living legend according to United Nations "],
      footPrints: "no one says money is not important, it indeed is important but what’s more important is that we share it to all those who places efforts in generating it. A financial equality. If there is anything you want to do in the best interest of the state/Nation – I am with you in it. Do it Words from the founder- Often defining discipline with a lifestyle is not easy. At 98 that man still visits the office at 4am in the morning, only if I could be a disciple to such discipline I will change the world. A phenomenal soul that still endeavors to aspire and inspire the youth. It still rings the bells when Sir Swaminathan said the words- Ravi –India is a pool of talent, we just have to reinstate the lost morale so our youth realizes how dangerous they can be, in taking the sorrow of an unfinished song to the grave."
    },
    {
    title: "Sir B.V.Naidu ",
    img: '/images/WhatsApp Image 2023-06-15 at 10.25.28 PM.jpeg',
    Description: '',
    Photos: '',
    SocialLinks : [],
    content: " “Wealth creation is an art – you create wealth, you build the country”-", 
    data: ["Father of the Silicon Valley of Bangalore", "Rajyotasav award Chairman of startup Xseed", 
    "Chairman of IIM, Chairman of KDEM", "Advisor to the IT taskforce chief minister"],
    footPrints: "It’s quite important for an endeavoring young man/ woman to have a guru with whom they feel safe, who could channel their energies into something substantial. Unfortunately, you seldom meet such phenomenal human beings who walk their talk. I am privileged and flushed/humbled by the divinity whenever I am in conversation with Naidu Sir as he has always been there in every step of the idea, extending his guidance/support in every layer personally and professionally. Nominating him for THE GREAT LIBRARY FRANCHISE to tell his story as to how come he was principle in building the silicon valley of India, does not even compensate for the respect, we as a Nation could pay to him. In fact we are honored he is letting us tell his story to the world. It’s interesting that he is the first one to write an email from The Great Indian Republic- then again as a matter of fact he is the first one to do many things.This was the man behind make in India idea, instrumental in bringing in software expo..etc. Despite of what he is at a professional front, world needs Great human beings like him to address the humanitarian front. It is Naidu sir coz of whom the algorithm of CST has evolved with entrepreneurship as the final destination."
  } ,
  {
    title: "Prof.Dato sri Dr.Mike Chan ",
    img: '/images/WhatsApp Image 2023-06-15 at 10.25.37 PM (1).jpeg',
    Description: '',
    Photos: '',
    SocialLinks : [],
    content: " “Have tolerance towards the loved ones, that’s the only way you establish relationship with the people of any Republic. Give them all a quality life style and a good health; after all we are scientists and Doctors for a reason”- ", 
    data: [],
    footPrints: "despite being the top 100 people that impacted global economy, if you take a closer look he is a perfect blend of the ideologies of Bruce Lee, Lee Kuan Yew and Mother Theresa. A perfect balance of an entrepreneur, a great human being and a very good husband. He is a perfect exempla gratia of a phoenix that rose from the ashes if one looks close into his life. Blessed are the students of the faculty of arts of The Conscious State he agreed to give his time and wisdom while documenting his story into the digital library of the stories of The Greatest people ever lived via THE GREAT LIBRARY. I am glad he is my mentor and an associate in bringing the state of the art swiss technology of bio regenerative Medicine to the Indian Republic in our endeavor to address the Nation’s Health Index – I feel safe already… Prof.Dr Ravi Tej Allam"
  },
  {
    title: "Bunker Roy ",
    img: '/images/WhatsApp Image 2023-06-15 at 10.25.27 PM.jpeg',
    Description: '',
    Photos: '',
    SocialLinks : [],
    content: " “whatever you wanted to do – do it like a black horse. You have to be powerful however you need to know when to display the muscle too. Strength is to serve the purpose of the lord. As for business – any proposal cannot be more than one page long. You will be able to spot the strengths in the smallest details to know if a person is focused and serious”- ", 
    data: [],
    footPrints: "The man’s journey is a spectacular one. The way he handled the political tyranny and surfaced as a times top 100 people building his empire in 140 countries, where in even a prime minister has been given a warm welcome by the people for the gratitude they have towards what an Indian man named Bunker Roy, its phenomenal, conveys the power of an idea. He is one such mentor that has taught me The Art of War. I am glad he is part and parcel of the e-commerce platform our associates were building to generate wealth to the people from humble origins."
  },
  {
    title: "Padma Shri Sir Thota Tharani  ",
    img: '/images/WhatsApp Image 2023-06-15 at 10.25.37 PM.jpeg',
    Description: '',
    Photos: '',
    SocialLinks : [],
    content: " “to the world I am an artist but what I do is a pure experiment like a scientist. I often try to encrypt the toughest riddles in the artistic form so I can trigger a thought of a viewer with a masterpiece. I meet with some phenomenal beings while performing the art –be it decoding symphony or Sir Ramanujan’s equation of infinity. Art has the power to reinstate the lost morale as long as the artist is powerful enough to make an impact.”- ", 
    data: [],
    footPrints: "A man so obsessed and passionate about art – could you imagine a man sitting outside the ICU of the hospital while his wife is in there and here is sketching some art. A man so engrossed with the art which is why his work is displayed next to Picasso in the international exhibitions. It’s a shame only few knew the value of an ingenious man living amongst ourselves. THE GREAT LIBRARY franchise is going to decode his artistic genius to the world. It’s a great privilege to say that the faculty of Arts and CST student’s global media is given an unconditional access to the 1000crore art work library of Padmashri Sir Thota Tharani which is due to auction on an international arena.I am glad Sir Thota Tharani agreed to offer his mastery to the feature film coming from CST Student’s global media in the name of I AM ASTAR."
  },
  // {
  //   title: "Ali ",
  //   img: '/images/WhatsApp Image 2023-06-15 at 10.25.27 PM (1).jpeg',
  //   Description: 'Dr. Ali is an actor who has been addressing the health and happiness index of the country . Spreading smiles on the problems statements called sorrow and agony, that cannot be seen , that which cannot be undermined . He is considered a phenomenal actor , comedian and television presenter who works predominantly in the telugu film industry and television . he was an awardee of 2 nandi awards and film fare awards We would like to take this opportunity to recognize doctor Ali garu and convey the unconventional jour journey of this phenomenal star who has been a part of creating a framework for the nations happiness index through his career of several decades via the great library, where matchless artists share their empowering humble origin stories on the global platform to the people of the global republic .',
  //   Photos: '',
  //   SocialLinks : ["https://www.instagram.com/ali_the_actor",
  //   "https://www.theconsciousstate.org",
  //   "inkedin.com/in/the-conscious-state-4b8716271/",
  //   ],
  //   content: "", 
  //   data: [],
  //   footPrints: "",
  // },
  // {
  //   title: "Brahmanadam ",
  //   img: '/images/WhatsApp Image 2023-06-15 at 10.25.32 PM.jpeg',
  //   Description: 'Actor Brahmanadam whose very name evokes a smile in the faces of cinema lovers is always felicitated as an eminent personality . His transition from a leccture to an accomplished actor is indeed indicative of the grate heights he has touched. His greatness can be understood by the fact of his ability to draw in crowds that kept him where he is toady, on the top. He created Guinness world record for the most screen cridits for a living actor. He was also awarded the title of Padma Shri, the fourth highest civilian award for his contributions to art. An actor is a man of character and a nice human being . He has been breaking systemic boundaries for decades.',
  //   Photos: '',
  //   SocialLinks : ["https://www.instagram.com/actorbrahmanandam/?hl=en",
  //   "https://www.linkedin.com/in/the-conscious-state-4b8716271/",
  //   "https://www.theconsciousstate.org",
  //   ],
  //   content: "", 
  //   data: [],
  //   footPrints: "",
  // } ,

]

const aboutUsData = [
  {
    
    img: '/images/WhatsApp Image 2023-06-18 at 5.20.08 PM.jpeg',
    name: 'Ravi Tej.A',
    designation: 'RESEARCH SCIENTIST MOLECULAR',
    specialition: 'BIOLOGIST',
    content: "The Conscious State is ideated by Prof Dr Ravi Tej Allam with an intention to create a place that acts as a one stop solution for Education/Research and Entrepreneurship. ", 
    content1: " It started as a global conglomerate of the 1% powerful brains responsible for innovations and artistic expressions that were creating a phenomenal impact, by acting as the solution providers for the world’s problem statements. "
  },
]




  return (
    <>
      <div className="conservation margin-left-right">
        {/* <StudentLogin /> */}
        <State data={data} />     
        <Container>
        <div className="my-4">
          <h2 className="main-titles">BIG HAPPENINGS</h2>
        </div>
        <Carousel
          arrows={true}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
        >
          <img
            src="images/png_20230516_081814_0000.png"
            alt=""
            className="bannerimage"
          />
          <img
            src="images/png_20230516_081746_0000.png"
            alt=""
            className="bannerimage"
          />
          <img src='images/png_20230408_173316_0000.png' alt=""
            className="bannerimage" />
        </Carousel>
        </Container>
        <State data={data1} />
        <Menters data={naiduData} />
      </div>
      <div></div>
      <About data={aboutUsData} title="About Us" />
      <CardsComponent title="MENTORS DIRECTORY" mentors={mentorsArr} />
      {/* <VideoComponent title="NEWS AND EVENTS" videos={[1, 2, 3, 4, 5]} /> */}
    </>
  );
};

export default Conservation;
