import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {HiChevronRight} from 'react-icons/hi'
import {NavLink} from "react-router-dom"

const Biological = () => {
  return (
    <Container className="Biological">
      <Row>
        <Col md={6}>
          <div className="bioHeader">
            The Center For Biological Aging Control, Regenerative Cell Therapy,
            And Health Management
          </div>
          <div className="bioText">
            The European Wellness Organisation was founded on the philosophy of
            assessing, treating and preventing chronic health disorders through
            patient-specific and integrative biological medicine down to the
            cellular level. At European Wellness, you are provided treatment and
            healthcare by experienced physicians and anti-aging specialists,
            using non and minimally invasive tools and technologies for a
            holistic approach to your wellness. Our Swiss-based cellular therapy
            and biomedical services are provided at accredited centers
            worldwide.
          </div>
          <NavLink to='/StdioAboutUs' className="bioButton">Learn More <HiChevronRight /></NavLink>
        </Col>
        <Col md={6}>
            <a href="https://www.youtube.com/watch?v=ZP694SHOybs" target="_blank" rel="noreferrer">
                <img src='/images/ew-intro02.jpg' alt='' className='w-100' />
            </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Biological;
