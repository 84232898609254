import React from 'react'
import HealthCard from './HealthCard';
import Biological from './Biological';
import Diagnoses from './Diagnoses';
import Centers from './Centers'; 
import "./CstStudio.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from './Product'
import Media1 from './Media1'
import Certified from './Certified'
import SuccessStories from './SuccessStories'
import { NavLink } from 'react-router-dom';

const CstStudio = () => {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 1192 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1191, min: 768 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 767, min: 0 },
          items: 1,
        },
      };
    
      const data = [{
        id: '1',
        img: '/images/BO__6888.jpg',
        header: 'A NIGHT TO REMEMBER: European Wellness Hospital Vietnam Hosts Gala Dinner to Celebrate its Grand Opening!',
        text: 'Class. Luxury. Elegance. All were exuded during European Wellness Vietnam’s Gala Dinner, where world-renowned celebrities,…',
        Date:"June 14, 2023",
    },
    {
        id: '2',
        img: '/images/2023-DSMC-EW-Launching-9.jpg',
        header: 'A NEW ERA OF REGENERATIVE MEDICINE IN VIETNAM: European Wellness Hospital Vietnam Launches in Ho Chi Minh City to STUNNING reception!',
        text: 'On June 11 2023, superstar celebrities and models as well as renowned doctors, physicians, and…',
        Date:"June 14, 2023",
    },
    {
        id: '3',
        img: '/images/DSMC-IMG-11-copy.jpg',
        header: 'European Wellness and China-Malaysia Health Technology Co. Ltd. Join Forces!',
        text: 'This April marked another milestone in the history of European Wellness’s global efforts as we…',
        Date:"June 14, 2023",
    },]

    const HealthTalkData = [{
      id: '1',
      img: 'https://www.youtube.com/embed/LWJx9D5hRYE?feature=oembed',
      header: 'Boost Intimacy And Genital Health With HIFU Vaginal Rejuvenation!',
      text: 'Good news for women! Unlock Your Sensual Potential with HIFU Vaginal Rejuvenation. In this insightful…',
      Date:"June 13, 2023",
  },
  {
      id: '2',
      img: 'https://www.youtube.com/embed/6j_mZwf_vzk?feature=oembed',
      header: 'What Is HIFU? The New, Safe Way For Skin Lifts! By Dr. Volodymyr Chernykh',
      text: "Lifting, tightening, and toning the skin doesn't have to be a surgical, invasive process. With…",
      Date:"June 2, 2023",
  },
  {
      id: '3',
      img: 'https://www.youtube.com/embed/UBfCe0s9cik?feature=oembed',
      header: 'Pico Laser: No Surgery Needed For Flawless Skin! By Dr. Volodymyr Chernykh',
      text: 'Improve skin blemishes and texture, reduce pores and acne scars, and look and feel your…',
      Date:"May 24, 2023",
  },]



  return (
    <div className="home">
      <Carousel
        arrows={true}
        responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
      >
        <div className="bannerImage image">
          <img src="images/stem-cell-jp-book.png" alt="" className="bannersmallimg" />
          <div className="d-flex flex-column">
            <img src="images/stem-cell-jp-text.png" alt="" className="w-100 h-50" />
            <div className="text-center">
              <NavLink type='button' className="bioButton">Learn More </NavLink></div>
          
</div>
        </div>

        <div className="bannerImage1 image">
          <p className="bannerText">
            WELCOME TO <br />
            EUROPEAN WELLNESS{" "}
          </p>
          <div className="vc_figure_text mb-3" style={{textAlign: "left"}}>Health Management & Therapies</div>
          <NavLink to='/StdioAboutUs' className="bioButton">Learn More </NavLink>
          <button type='button' className="bioButton ms-3" style={{backgroundColor: "rgb(46, 59, 151)"}}>Contact Us </button>
        </div>

        <div className="bannerImage2 image">
          <p className="bannerText">EW VILLA MEDICA</p>
          <div className="vc_figure_text mb-3" style={{textAlign: "left"}}>Germany</div>
          <NavLink to='/european-wellness-centers' className="bioButton">Learn More </NavLink>
          <button type='button' className="bioButton ms-3" style={{backgroundColor: "rgb(46, 59, 151)"}}>Contact Us </button>
        </div>

        <div className="bannerImage3 image">
          <p className="bannerText">
            GERMAN <br />
            MEDICAL CENTER{" "}
          </p>
          <div className="vc_figure_text mb-3" style={{textAlign: "left"}}>Frankfurt, Germany </div>
          <NavLink to='/european-wellness-centers' className="bioButton">Learn More </NavLink>
          <button type='button' className="bioButton ms-3" style={{backgroundColor: "rgb(46, 59, 151)"}}>Contact Us </button>

        </div>
        <div className="bannerImage4 image">
          

        </div>
      </Carousel>
      <HealthCard />
      <Biological />
      <Diagnoses /> 
      <Centers />
      <SuccessStories />
      <Product />
      <Media1 data={HealthTalkData} title="Health Talk" />
      <Media1 data={data} title="What's Happening" />
      <Certified />
    </div>
  )
}

export default CstStudio
