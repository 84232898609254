import React from 'react'
import {
    Container,
    Row,
     Card
  } from "react-bootstrap";
  import { NavLink } from "react-router-dom";

const Centers = () => {
    const data = [
        {
            id: '1',
            img: '/images/german-medical-center.jpg',
            header: 'German Medical Center',
            text: 'Frankfurt, Germany'
        },
        {
            id: '2',
            img: '/images/orthobiotiki.jpg',
            header: 'Orthobiotiki By European Wellness',
            text: 'Athens, Greece'
        },
        {
            id: '3',
            img: '/images/swiss-wellnes-center.jpg',
            header: 'Swiss Wellness Center',
            text: 'Lugano, Switzerland'
        },
        {
            id: '4',
            img: '/images/EWVM.jpg',
            header: 'EW Villa Medica Germany Premier Center',
            text: 'Edenkoben, Germany'
        },
        {
            id: '5',
            img: '/images/ew-sanorell-pharma.jpg',
            header: 'EW Sanorell Pharmaceutical Plant',
            text: 'Baiersbronn, Germany'
        },
        {
            id: '6',
            img: '/images/villa-medica-th.jpg',
            header: 'EW Villa Medica Bangkok Premier Center',
            text: 'Bangkok, Thailand'
        },
        {
            id: '7',
            img: '/images/villa-medica-bangladesh.jpg',
            header: 'EW Villa Medica Bangladesh Premier Center',
            text: 'Dhaka, Bangladesh'
        },
        {
            id: '8',
            img: '/images/ew-malaysia.jpg',
            header: 'EW Academy & Showcase',
            text: 'Kota Kinabalu, Malaysia'
        },
        {
            id: '9',
            img: '/images/ghhs-wellness-center.jpg',
            header: 'European Wellness Retreat',
            text: 'The Mines, Kuala Lumpur, Malaysia'
        },
    ]
  return (
    <Container className='Biological'>
        <Row>
        <div className="bioHeader">
            European Wellness Centers
          </div>
          <div className='centerText mb-5'>Our Healthcare Center Are Available All Around The World</div>
          {data.map((each, id) => 
          <Card key={id} className='col-md-4'>
            <img src={each.img} alt='' className='centerImage' />
            <div className='centerHeader'>{each.header}</div>
            <div className='centerText'>{each.text}</div>
            <NavLink to='/european-wellness-centers' className='centerButton'>Learn More</NavLink>
          </Card>)}
        </Row>
      
    </Container>
  )
}

export default Centers
