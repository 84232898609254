import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";

const Studio1 = ({data}) => {
  return (
     <Container>
        <Row>
          <Col md={6} className="home">
            <div className="our_centers_heading">{data.head}</div>
            <div className="our_centers_small_text">
            {data.sHead}
            </div>
            <div className="para mb-2">
            {data.para}
            </div>
            {data.hasOwnProperty("button" ) && 
            <NavLink to={data.link} className="bioButton mt-2">
              {data.button}{" "}
              <BsBoxArrowUpRight />
            </NavLink>}
          </Col>
          <Col md={6}>
            {data.hasOwnProperty("frame" ) ? 
            <iframe
              src={data.frame}
              height="100%"
              width="100%"
              title="Iframe Example"
            ></iframe> :
            <img src={data.img} className="w-100 h-100" alt="" />
            }
          </Col>
        </Row>
      </Container>
  )
}

export default Studio1
