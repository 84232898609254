import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const SchoolAcademy = () => {
  return (
    <div className="margin-left-right">
      <div className="school main-titles">ACADEMY – ART OF DESIGN</div>
      <div className="school cotetion">
        Learning should unshackle a soul from all insecurities and unleash the
        liberated spirit to endanger the insanity; such learning shall only
        happen in a pious environment through enlightened masters who are in
        abundance in love.
      </div>
      <div className="school">Think Organise Structure Execute</div>
      <Container>
        <Row>
          <Col xs={12} className="content">
            The Academy will prepare students for real-world professions by
            giving them practical experience in different streams of science and
            arts, and provide opportunities to pursue careers in areas they are
            passionate about.
          </Col>
          <Col xs={12} className="content">
            The Academy shall give students hands-on training in design and
            innovation, and encourage them to explore their strengths, while
            securing their careers/places through their mentors. Armed with
            superior problem-solving capacities and research methodologies, our
            students will be ready to crack any competitive exam
          </Col>
          <Col xs={12} className="content">
            The Academy is at the heart of one of the important objectives of
            the CST: to be a platform for the greatest innovations and artistic
            expressions. Here students shall be trained and actively take part
            in different scientific/entrepreneurial projects from different
            departments, so they can be exposed to the principles of science,
            cutting-edge technologies, and R&D.
          </Col>
          <Col xs={12} className="content">
            The Academy is at the heart of one of the important objectives of
            the CST: to be a platform for the greatest innovations and artistic
            expressions. Here students shall be trained and actively take part
            in different scientific/entrepreneurial projects from different
            departments, so they can be exposed to the principles of science,
            cutting-edge technologies, and R&D.An important objective in working
            with the academic mentors is that students get a chance to know how
            they attained technical and intellectual excellence, and overcame
            the odds to come up with original ideas.
          </Col>
          <div className="school cotetion">
            {" "}
            “Learn and see through the living souls”{" "}
          </div>
          <Col xs={12} className="content">
            Since the collaborators of CST are spread out throughout the
            country, students of the Academy can also meet them online.
          </Col>
          <Col xs={12} className="descrption">
            Selection Process
          </Col>
          <Col xs={12} className="content">
            The Academy is open to all the students, from schooling to
            university, until post-Doctoral level.
          </Col>
          <Col xs={12} className="content">
            (However, our belief is that earning a Degree should not be the
            criterion to enter the Academy; rather, it is the willingness to
            learn, explore and design that will get you a place here.)
          </Col>
          <Col xs={12} className="content">
            A student interested in joining has to express their intent in the
            form of an Expression of Interest (EOI) detailing their persona and
            why they want to join us, and where their interest lies. We take
            references/EOI into consideration to shortlist the students who will
            be invited for a personal interview between the faculty, student and
            the mentor. Meeting with the mentor shall happen in person or over a
            telephone conference, whereby they will provide the guidelines as to
            what student needs to be equipped with, in order to work on the
            designated project.
          </Col>
          <a href="https://theconsciousstate.org/docs/CST%20School%20function.pdf">
            Download CST School function PDF
          </a>
          <a href="https://theconsciousstate.org/docs/CST Student.docx">
            Download Student Empowerment PDF
          </a>
          <div className="school cotetion">
            {" "}
            “Academy is where students associate with professionals who are into
            the code of CST - 3M’Mother Nature, Universal Master, Me (Self)
            which is understanding Environment, Universe Space and biology in
            scientific terms. This is where they shall learn art of living and
            war”
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SchoolAcademy;
