import React from "react";
import { Row, Col, FloatingLabel, Form, Container } from "react-bootstrap";

const StudentLogin = () => {
  return (
    <Container>
      <div className="my-4">
        {/* <h2 className="main-titles">Student Membership Document</h2> */}
        <Row className="sMembership">
          <Col md={6} className="form">
            <h5>
              Want to be a part of the Student’s community at CST – You are one
              registration away
            </h5>
            <FloatingLabel
              controlId="floatingInput"
              label="Name"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Enter Your Name" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Education"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Education" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Passion"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Passion" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Mentor of Interest"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Mentor of Interest" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Inspiration"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Inspiration" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Ambition"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Ambition" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Expectations from CST(How can we help you?)"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Expectations from CST(How can we help you?)" />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Social Media handles – "
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Social Media handles – " />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingPassword"
              label="Ambition"
              className="mb-3"
            >
              <Form.Control type="text" placeholder="Ambition" />
            </FloatingLabel>
           
          </Col>
          <Col md={6} className="p-3">
          <h5>
          Payment portal
          <img src='/images/WhatsApp Image 2023-06-18 at 2.27.37 PM.jpeg' alt='' className="menterimage" />
            </h5>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default StudentLogin;
