import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ConnectState from "../../view/ConnectState";
import SubHeader from "../../view/SubHeader";
import { BiSearch } from "react-icons/bi";
import { RiUserFill } from "react-icons/ri";
import { GiPayMoney } from "react-icons/gi";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";
import { Container, Dropdown, Modal, Button } from "react-bootstrap";
import StudentLogin from '../../view/StudentLogin'

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [slug, setSlug] = useState("");
  const [studentLogin, setStudentLogin] = useState(false)
  const [Entrepreneurs, setEntrepreneurs] = useState(false)
  const [InvestorsLogin, setInvestorsLogin] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    setSlug(location.pathname);
  }, [location]);

  useEffect(() => {
    setShowMenu(false);
  }, [slug]);

  //const handelTop = () => {};

  return (
    <div className="topFix maxWidthContainerFluid">
      <ConnectState />
      <Container fluid className="headerContainer">
        <div className="header">
        <div className="nav-toggle" onClick={toggleMenu}>
          {/* <span className={showMenu ? "bar x" : "bar"} />
              <span className={showMenu ? "bar x" : "bar"} />
              <span className={showMenu ? "bar x" : "bar"} /> */}
          {showMenu ? <RxCross2 /> : <RxHamburgerMenu />}
        </div>
        <NavLink to="/" className="logo" style={{ margin: 0, padding: 0 }}>THE CONSCIOUS STATE</NavLink>
        <div className="rightMenu">
          <NavLink to="" className="logo1">
            <BiSearch />
          </NavLink>
          <GiPayMoney className="logo1 ms-1 mx-sm-3" />
          <Dropdown>
      <Dropdown.Toggle id="dropdown-basic">
      <RiUserFill />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => setStudentLogin(true)} href="">Student Login</Dropdown.Item>
        <Dropdown.Item onClick={() => setEntrepreneurs(true)} href="">Entrepreneurs Login</Dropdown.Item>
        <Dropdown.Item onClick={() => setInvestorsLogin(true)} href="">Investors Login</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> 
         
        </div>
        </div>

        <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered show={studentLogin} onHide={() => setStudentLogin(false)}>
        <Modal.Header closeButton>
          <h2 className="main-titles">Student Membership Document</h2>
        </Modal.Header>
        <Modal.Body>
           <StudentLogin />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setStudentLogin(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered show={Entrepreneurs} onHide={() => setEntrepreneurs(false)}>
        <Modal.Header closeButton>
          <h2 className="main-titles">Entrepreneurs Membership Document</h2>
        </Modal.Header>
        <Modal.Body>
           <StudentLogin />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setEntrepreneurs(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered show={InvestorsLogin} onHide={() => setInvestorsLogin(false)}>
        <Modal.Header closeButton>
          <h2 className="main-titles">Investors Membership Document</h2>
        </Modal.Header>
        <Modal.Body>
           <StudentLogin />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setInvestorsLogin(false)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
        
     
      {showMenu && (
        <div className="menuBar">
          <NavLink to="/trust" className="menuNav">
            CST Trust
          </NavLink>
          <NavLink to="/school" className="menuNav">
            CST School
          </NavLink>
          <NavLink to="/schoolacademy" className="menuNav">
            School Academy
          </NavLink>
          <NavLink to="/studio" className="menuNav">
            Consciousstudio.pvt.ltd
          </NavLink>
          <NavLink to="/healthcare" className="menuNav">
            CST Healthcare
          </NavLink>
          <NavLink to="/media" className="menuNav">
            CST Media
          </NavLink>
          <NavLink to="/techsolutions" className="menuNav">
            Tech Solutions
          </NavLink>
          <NavLink to="/projectsandevents" className="menuNav">
            Projects and Events
          </NavLink>
          <NavLink to="/mentors" className="menuNav">
            Mentors 
          </NavLink>
          <NavLink to="" className="menuNav">
            Calender
          </NavLink>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
            >
              Enterprenuership
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className="menuNav">
                Faculty of Science
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2" className="menuNav">
                Faculty of Arts
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <NavLink to="" className="menuNav">
            Donate
          </NavLink>
          <NavLink to="" className="menuNav">
            About Us
          </NavLink>
          <NavLink to="" className="menuNav">
            Contact Us
          </NavLink>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
            >
              More
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className="menuNav">
                career
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2" className="menuNav">
                Visions and Values
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1" className="menuNav">
                Documents
              </Dropdown.Item>
              <Dropdown.Item href="/faqs" className="menuNav">
                FAQs
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <SubHeader />
      </Container>
    </div>
  );
};

export default Header;
