import React from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import {HiChevronRight} from 'react-icons/hi'
import {NavLink} from "react-router-dom"

const Diagnoses = () => {
  return (
    <div className="Diagnoses Biological d-none d-md-block">
      <div className="mainCon"></div>
      <div className="boderBottom">
        <svg
          className="wpex-shape-divider-svg"
          style={{ height: "50px" }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="wpex-shape-divider-path"
            fill="#fff"
            d="M737.9,94.7L0,0v100h1000V0L737.9,94.7z"
          ></path>
        </svg>
      </div>
      <Container className="">
        <Row className="">
          <Col md={6} style={{ position: "absolute", left: "0%", top: "10%" }}>
            <Row style={{ top: "20%" }}>
              <Col md={2}></Col>
              <Col md={2}>
                <img
                  src="/images/sleep-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Sleep</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/stress-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText"> Stress</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/athletes-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Athletes</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/smokers-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Smokers</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/weight-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">
                  Weight <br /> Management
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "35px" }}>
              <Col md={2}></Col>
              <Col md={2}>
                <img
                  src="/images/aging-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Aging</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/aesthetic-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText"> Aesthetic</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/toxin-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Toxin</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/hormonal-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Hormonal</div>
              </Col>
              <Col md={2}>
                <img
                  src="/images/pain-icon-80x80.png"
                  alt=""
                  className="dioIcon"
                />
                <div className="dioText">Pain</div>
              </Col>
            </Row>
          </Col>
          <Col md={6} style={{ position: "absolute", left: "50%", top: "10%", paddingLeft:'65px' }}>
            <div className="DiagnosesHeader">Health Diagnoses</div>
            <div className="subHeader1">
              At European Wellness, Our Primary Focus Is You. How Can We Help
              You?
            </div>
            <div className="content">
              Each patient is unique. Our experts employ advanced diagnostic
              methods to assess your physiological, mental, and emotional
              wellbeing, before creating a completely personalised healthcare
              plan based on cell therapy, regenerative medicine, and healthy
              lifestyle adjustments tailored to your individual needs.
            </div>
            <NavLink to='/health-diagnose' className="bioButton Diabutton mt-5">Learn More <HiChevronRight /></NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Diagnoses;
