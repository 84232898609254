import React from 'react'
import Studi0Banner from './Studi0Banner'
import { Container, Row, Col } from "react-bootstrap";

const HealthDiagnosesStudio = () => {

  const Diagnoses = [
    {
      id: 0,
      head: 'Hormonal',
      text: "Our unique biohormonal revitalisation program provides complementary treatment for premature menopause and andropause. The main principle of this therapy is hormone-free modulation of endogenous endocrine function using a combination of safe non-invasive procedure.",
      img: '/images/hormonal-icon-80x80.png'
    },
    {
      id: 1,
      head: 'Toxin',
      text: "Levels of toxicity undermines our recovery rates and immunisation.",
      img: '/images/toxin-icon-80x80.png'
    },
    {
      id: 2,
      head: 'Aesthetic',
      text: "Our appearance is a reflection of the state of our internal health and state of mind. Our cellular therapy and imperfections.",
      img: '/images/aesthetic-icon-80x80.png'
    },
    {
      id: 3,
      head: 'Pain',
      text: "We implement a drug-free approach to pain management based on scientifically proven methods and natural therapies. Every effort is put to influence core physiological factors of chronic inflammation and tissue damage along with identifying and repairing blockages of energy pathways through the body.",
      img: '/images/pain-icon-80x80.png'
    },
    {
      id: 4,
      head: 'Sleep',
      text: "Insomnia and other sleep disturbances are attributes of modern times; fast paced lifestyle, smartphones and tablets, stress. Recognizing clearly the underlying pathophysiology of sleep, we have developed profound solutions to manifest regular sound sleep.",
      img: '/images/sleep-icon-80x80.png'
    },
    {
      id: 5,
      head: 'Stress',
      text: "People have different ways of reacting to stress, so a situation that feels stressful to one person may be motivating to someone else. This is why there is not just one solution, and treatments and therapies must be individualised. We provide unshakeable holistic de-stress response provision.",
      img: '/images/stress-icon-80x80.png'
    },
    {
      id: 6,
      head: 'Athletes',
      text: "Whether CrossFit, TacFit, weightlifting, cycling, kickboxing or any number of Olympic sports, we work with sports scientists to determine the best holistic support for performance enhancement or recovery. With knowledge of the World Anti-Doping Authority’s regulations, we help champions go faster, harder, and stronger without drugs.",
      img: '/images/athletes-icon-80x80.png'
    },
    {
      id: 7,
      head: 'Smokers',
      text: "It’s a tough habit to break and we perfectly understand that some people may never quit this terrible deadly habit. We can and will do our best to reduce the damage inflicted on a Guest’s lungs, heart, brain blood and circulation.",
      img: '/images/smokers-icon-80x80.png'
    },
    {
      id: 8,
      head: 'Weight Management',
      text: "Our weight management program is a fusion of nutritiology, metabolic medicine and non-medicinal non-invasive fat burning procedures. With almost immediate and lasting effect we can help to increase metabolic rate and channel it from fat deposition to muscle strengthening.",
      img: '/images/weight-icon-80x80.png'
    },
    {
      id: 9,
      head: 'Aging',
      text: "Premature aging can lead to discomfort and advanced biological age. We address premature aging by assessing and diagnosing the causes, detoxing and revitalising procedures to help regenerate defective While we are restricted to aging caused by hereditary factors we are able to manage the effects of premature aging and the effects of aging caused by external factors. Here we try to tackle root causes that contribute to your premature aging with thorough inner cleansing and regenerative therapies",
      img: '/images/aging-icon-80x80.png'
    },
  ]
  return (
    <div className="our-centers">
      <Studi0Banner txt='Health Diagnoses' img='bannerImage3' />
      <Container>
        <Row>
          <Col className="home">
            <div className="our_centers_heading">Health Diagnoses</div>
            <div className="para mb-2">
            European Wellness’s holistic approach to healthcare allows us to assess various 
            aspects of your physiological, mental and emotional wellbeing. Our team of 
            specialists, cutting-edge biomedical devices and state-of-the-art facilities are 
            well-equipped to diagnose any underlying
             conditions and provide you with the best customised treatment.
            </div>
            </Col>
            </Row>
            <Row>
              {Diagnoses.map((each, t) => (
                <Col md={6} key={t} className='d-flex my-3'>
                  <Col md={3}>
                  <img src={each.img} alt='' className='diagnoseImage' />
                  </Col>                
                  <Col md={9}>
                    <div className='vc_label'>{each.head}</div>
                    <div>{each.text}</div>
                  </Col>
                </Col>
              ))}
            </Row>
            </Container>
    </div>
  )
}

export default HealthDiagnosesStudio
