import React from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";

const HealthCard = () => {
  return (
    <Container>
      <Row>
        <Col md={3} className="healthCard">
          <div className="vc_figure">
            <img src="/images/health-management-icon-100x100.png" alt="" />
          </div>
          <div className="vc_figure_text">Health Management & Therapies</div>
          <NavLink to="/health-management-therapies" className="vc_figure_button">
            Learn More
          </NavLink>
        </Col>
        <Col md={3} className="healthCard" style={{backgroundColor:'#f5a81c'}}>
          <div className="vc_figure">
            <img src="/images/diagnoses-icon-100x100.png" alt="" />
          </div>
          <div className="vc_figure_text">Health <br />Diagnoses</div>
          <NavLink to="/health-diagnose" className="vc_figure_button">
            Learn More
          </NavLink>
        </Col>
        <Col md={3} className="healthCard">
          <div className="vc_figure">
            <img src="/images/wellness-center-icon-100x100.png" alt="" />
          </div>
          <div className="vc_figure_text">Wellness <br/>Centers</div>
          <NavLink to="/european-wellness-centers" className="vc_figure_button">
            Learn More
          </NavLink>
        </Col>
        <Col md={3} className="healthCard" style={{backgroundColor:'#f5a81c'}}>
          <div className="vc_figure">
            <img src="/images/specialists-icon-100x100.png" alt="" />
          </div>
          <div className="vc_figure_text">Advisory</div>
          <NavLink to="/advisory" className="vc_figure_button">
            Learn More
          </NavLink>
        </Col>
      </Row>
    </Container>
  );
};

export default HealthCard;
