import React from 'react'
import { Container, Row, Card } from "react-bootstrap";
import {HiChevronRight} from 'react-icons/hi'

const Media1 = ({data, title}) => {
    
  return (
    <Container className='Biological my-5'>
        <Row>
        <div className="bioHeader my-3 text-center">
        {title}
          </div>
          {data.map((each, id) => 
          <Card key={id} className='col-md-4'>
            {title === "Health Talk" ? 
            <iframe src={each.img} height="100%" width="100%" title="Iframe Example"></iframe>
             : <img src={each.img} alt='' className='centerImage' />}
            
            <div className='centerHeader h-25'>{each.header}</div>
            <div>{each.Date}</div>
            <div className='centerText my-3'>{each.text}</div>
            <button type='button' className="bioButton">Read More <HiChevronRight /></button>

            {/* <button type='button' className='centerButton'>Read More</button> */}
          </Card>)}
        </Row>
      
    </Container>
  )
}

export default Media1
