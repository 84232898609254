import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";

const Media = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1192 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1191, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <Container className="Media">
      <div className="ItemBanner">
        <img src="images/banner-2.jpg" alt="" className="imgSvg" />
        <img src="images/banner-2.svg" alt="" className="imgSvg" />
      </div>
      <Row className="panLeaderText">
        <Col className="d-none d-md-block" md={3}><hr className="poinLine"/></Col>
        <Col md={3} className="poinText">Pioneers in</Col>
        <Col md={3} className="panLeaderCola">SPORTAINMENT</Col>
        <Col className="d-none d-md-block" md={3}><hr className="poinLine"/></Col>
      </Row>
      <Row className="mt-3">
        <Col md={3} data-aos="fade-up" data-aos-duration="800">
          <div className="podcastImage image1">
            <p className="podcastText">Concerts</p>
          </div>            
        </Col>
        <Col md={3} data-aos="fade-down" data-aos-duration="800">
        <div className="podcastImage image2">
        <p className="podcastText">Podcasts</p>
        </div>            
        </Col>
        <Col md={3} data-aos="fade-up" data-aos-duration="800">
        <div className="podcastImage image3">
        <p className="podcastText">Movies</p>
        </div>           
        </Col>
        <Col md={3} data-aos="fade-down" data-aos-duration="800">
          <div className="podcastImage image4">
          <p className="podcastText">Documentary</p>
          </div>            
        </Col>
      </Row>
      <Row className="mt-3">
        <div className="contentHeader mb-3">
        ICONIC COLLABORATIONS
    </div>
    <div>
    <Carousel
          arrows={true}
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={2000}
          infinite={true}
          swipeable={true}
        >
           <div data-aos="fade-up" data-aos-duration="800" className='iconicImage'>
          <img
            src="images/2.png" alt="" className="image"
          />
          <button type="button" className='knowButton'>KNOW MORE</button>
          </div>
           <div data-aos="fade-down" data-aos-duration="800" className='iconicImage'>
          <img
            src="images/3.png" alt="" className="image"
          />
          <button type="button" className='knowButton'>KNOW MORE</button>
          </div>
           <div data-aos="fade-up" data-aos-duration="800" className='iconicImage'>
          <img
            src="images/4.png" alt="" className="image"
          />
          <button type="button" className='knowButton'>KNOW MORE</button>
          </div>
           <div className='iconicImage' data-aos="fade-up" data-aos-duration="800">
          <img
            src="images/5.png" alt="" className="image"
          />
          <button type="button" className='knowButton'>KNOW MORE</button>
          </div>
           <div data-aos="fade-down" data-aos-duration="800" className='iconicImage'>
          <img
            src="images/6.png" alt="" className="image"
          />
          <button type="button" className='knowButton'>KNOW MORE</button>
          </div>
         
        </Carousel>
    </div>
     
      </Row>
    </Container>
  );
};

export default Media;
