
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { GoogleMap, MarkerF, LoadScript } from "@react-google-maps/api";
import ConnectState from "../../view/ConnectState";
import { Container } from "react-bootstrap";

const Footer = () => {
  const [position, setPosition] = useState({ lat: 17.452761, lng: 78.383743 });
  const [isLoaded, setIsLoad] = useState(false);

  const center = {
    lat: 17.452761,
    lng: 78.383743,
  };

  const style = {
    height: "70vh",
    width: "100%",
  };

  useEffect(() => {
    if (isLoaded) setPosition({ lat: 17.452761, lng: 78.383743 });
  }, [isLoaded]);

  //const YOUR_API_KEY = 'AIzaSyBGFR6sytAQf7jQZo1vZIESPs9ipLQtAms'

  const onLoad = (marker) => {
    setIsLoad(true);
   
  };

  const markerLabelStyle = {
    color: "white",
    backgroundColor: "black",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "8px",
    borderRadius: "4px",
  };
  
  return (
    <Container>
      <div className="mt-5 footer">
        <div className="row">
          <div className="col-md-6">
            <div className="p-4">
              <div className="py-3">
                <NavLink to="/">
                  <img
                    src="/images/IMG-20230408-WA0004.jpg"
                    alt="logo"
                    style={{
                      width: "40%",
                      height: "40%",
                    }}
                  />
                </NavLink> 
              </div>
              <div>
                <div className="py-2">
                  <strong
                    style={{ color: "#2596be", fontSize: "1.2rem" }}
                    className="pe-2"
                  >
                    Phone:
                  </strong>{" "}
                  <a
                    href="tel:+919121777777"
                    style={{ color: "#ffffff", fontSize: "1.1rem" }}
                    className="footer-ira-reality"
                  >
                    {" "}
                    +91 9908180724
                  </a>
                </div>
                <div className="py-2">
                  <strong
                    style={{ color: "#2596be", fontSize: "1.2rem" }}
                    className="pe-2"
                  >
                    Email:{" "}
                  </strong>
                  <span
                    style={{ color: "#ffffff", fontSize: "1.1rem" }}
                    className="footer-ira-reality"
                  >
                    {" "}
                    info@rg2its.com, admin@rg2its.com
                  </span>
                </div>
                <div className="py-2">
                  <strong
                    style={{ color: "#2596be", fontSize: "1.2rem" }}
                    className="me-2"
                  >
                    Address:{" "}
                  </strong>
                  <span
                    style={{ color: "#ffffff", fontSize: "1.1rem" }}
                    className="footer-ira-reality"
                  >
                    {" "}
                    #301,T R Square, Jai Hind Enclave, Ayyappa Society, Swimming
                    Pool Road, Madhapur, Hyderabad- 500081
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3">
              <div className="mb-2">
                <h2
                  style={{
                    color: "#fff",
                    fontWeight: "700",
                    letterSpacing: "2px",
                  }}
                >
                  Our Location
                </h2>
                <hr className="hr-one m-0" />
              </div>
              <LoadScript googleMapsApiKey="AIzaSyBGFR6sytAQf7jQZo1vZIESPs9ipLQtAms">
                <GoogleMap mapContainerStyle={style} zoom={16} center={center}>
                  <MarkerF
                    onLoad={onLoad}
                    position={position}
                    label={{ text: "RG2IT SOLUTIONS", style: markerLabelStyle }}
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ color: "#ffffff" }} />
      <div className="col-lg-12 text-center">
        <p style={{ color: "#ffffff" }}>
          © Copyright 2023 The Conscious State . All Rights Reserved
        </p>
      </div>
      <ConnectState />
      <div className="subHeader">
      <NavLink to="/about-us" className="sublogo1">
      About Us
      </NavLink>
      <NavLink to="/privacy-policy" className="sublogo1">
      Privacy Policy
      </NavLink>
      <NavLink to="/becomea-a-member" className="sublogo1">
      Become a Member
      </NavLink>
      <NavLink to="/became-a-contributor" className="sublogo1">
      Became a Contributor
      </NavLink>
      <NavLink to="/become-an-investor" className="sublogo1">
      Become an Investor
      </NavLink>
      <NavLink to="/become-an-entrepreneur" className="sublogo1">
      Become an Entrepreneur
      </NavLink>
      <NavLink to="/site-partners" className="sublogo1">
      Site Partners
      </NavLink>
    </div>
    </Container>
  );
};

export default Footer;
