import React from "react";
// import { IoIosClose } from "react-icons/io";
// import { CiFacebook } from "react-icons/ci";
// import { AiFillTwitterCircle } from "react-icons/ai";
// import { FaLinkedin } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container } from "react-bootstrap";

const CardsComponent = ({ title, mentors }) => {

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1401 },
      items: 5,
      partialVisibilityGutter: 5,
    },
    tablet: {
      breakpoint: { max: 1400, min: 768 },
      items: 3,
      partialVisibilityGutter: 80,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 80,
    },
  };

  return (
    <Container className="mentors-container margin-left-right">
      <div className="my-4">
        <h2 className="main-titles">{title}</h2>
      </div>
      <div className="container">
        <div className="row">
        <Carousel
              arrows={true}
              responsive={responsive}
              partialVisible={true}
            >
          {mentors.map((i, ind) => 
                <div key={ind}
                >
                  <div className="card mx-2" style={{ height: "24rem" }}>
                    <img src={i.image} className="card-img-top" style={{objectFit: "contain",
    borderRadius: "100%"}} alt='' />
                    <div className="card-body">
                      <h5 className="card-title">{i.name}</h5>
                      <p>{i.appearance}</p>
                    </div>
                  </div>
                </div>
              )}
          </Carousel>
                {/* <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"  
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                       <button
                        type="button"
                        className="btn-close ms-auto p-3"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button> 
                      <div className="modal-body">
                        <div className="d-flex justify-content-between">
                          <div className="">
                            <img
                              src="/images/thota.webp"
                              alt=".."
                              style={{ width: "10rem" }}
                            />
                          </div>
                          <div>
                            <h2>Dr Tolla Raj Kumar</h2>
                            <p>speciality</p>
                            <strong>A Short description</strong>
                            <p>
                              phone: <span>+91 92384993294</span>
                            </p>
                            <p>
                              Email: <span>sri902kanth@gmail.com</span>
                            </p>
                            <div>
                              <a
                                href="https://www.linkedin.com/in/bunker-roy-91ab8b6/?originalSubdomain=in "
                                className="mx-2"
                              >
                                <CiFacebook />
                              </a>
                              <a
                                href="https://www.linkedin.com/in/bunker-roy-91ab8b6/?originalSubdomain=in "
                                className="mx-2"
                              >
                                <AiFillTwitterCircle />
                              </a>
                              <a
                                href="https://www.linkedin.com/in/bunker-roy-91ab8b6/?originalSubdomain=in "
                                className="mx-2"
                              >
                                <FaLinkedin />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
             
        </div>
      </div>
    </Container>
  );
};

export default CardsComponent;
