import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const State = ({data}) => {
  return (
    <Container>
      <div className="my-4">
        <h2 className="main-titles">{data.title}</h2>
      </div>
      <div className="podcastText">
        {data.data}
      </div>
    </Container>
  );
};

export default State;
