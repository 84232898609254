import React from 'react'
import { Container,Row, Col } from 'react-bootstrap'

const Certified = () => {
  return (
    <Container className='Certified'>
        <Row>
            <Col md={3}>
                <img src='/images/CPD-logo-150x144-1.png' alt='' />
            </Col>
            <Col md={9}>
            <div className="bioHeader mt-3" style={{color: '#fff'}} >
            European Wellness Academy™
          </div>
          <div className='centerText mb-5' style={{color: '#fff'}}>CPD Certified Training Provider</div>

            </Col>
        </Row>
      
    </Container>
  )
}

export default Certified
