import React from "react";
import Carousel from "react-multi-carousel";
import { Container } from "react-bootstrap";

const Product = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1192 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1191, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  return (
    <Container className="my-3">
         <Carousel
      arrows={true}
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={2000}
      infinite={true}
      swipeable={true}
    >
      <img
        alt=""
        src="/images/books-promo-1.jpg"
        style={{
          display: "block",
          height: "100%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        alt=""
        src="/images/books-promo-3 (1).jpg"
        style={{
          display: "block",
          height: "100%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        alt=""
        src="/images/books-promo-3.jpg"
        style={{
          display: "block",
          height: "100%",
          margin: "auto",
          width: "100%",
        }}
      />
    </Carousel>
    </Container>
   
  );
};

export default Product;
