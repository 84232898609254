import React from 'react'
import Studi0Banner from './Studi0Banner'
import { Col, Container, Row } from 'react-bootstrap'

const AdvisoryStudio = () => {
  const menters = [
    {
      id: 0,
      head: 'Dr. Dmytro Klokol',
      text: "Head of Medical Advisory Board",
      img: '/images/DYMYTRO.jpg',
      link: '/dr-dmytro-klokol'
    },
    {
      id: 1,
      head: 'Prof. Dato Sri Dr. Mike Chan',
      text: "Senior Technical Consultant",
      img: '/images/MIKE.jpg',
      link: "/prof-dr-mike-chan"
    },
    {
      id: 2,
      head: 'Prof. Dr. Yuriy Nalapko',
      text: "Medical Advisor",
      img: '/images/YURIY.jpg',
      link: "/prof-dr-yuriy-nalapko"
    },
    {
      id: 3,
      head: 'Dr. Volodymyr Chernykh',
      text: "Medical Advisor",
      img: '/images/VLADIMIR.jpg',
      link: "/dr-volodymyr-chernykh"
    },
    {
      id: 4,
      head: 'Dr. Simon Yefimov',
      text: "Medical Advisor",
      img: '/images/SIMON.jpg',
      link: "/dr-simon-yefimov"
    },
    {
      id: 5,
      head: 'Dr. Dina Tulina',
      text: "Medical Advisor",
      img: '/images/DINA.jpg',
      link: "/dr-dina-tulina"
    },
    {
      id: 6,
      head: 'Dr. Margaryta Iemelianova',
      text: "Medical Advisor",
      img: '/images/MARGARYTA-LEMELIANOVA.jpg',
      link: "/dr-margaryta-iemelianova"
    },
    {
      id: 7,
      head: 'Prof. Dr. Roni Lara Moya',
      text: "Medical Advisor",
      img: '/images/RONI-MOYA.jpg',
      link: "/prof-dr-roni-lara-moya"
    },
    {
      id: 8,
      head: 'Dr. Bosco Zolk',
      text: "Medical Director",
      img: '/images/BOSCO-ZOLK.jpg',
      link: "/dr-bosco-zolk"
    },
    {
      id: 9,
      head: 'Prof. Dr. Olha Nishkumai',
      text: "Medical Consultant",
      img: '/images/drolha-1.jpg',
      link: "/prof-dr-olha-nishkumai"
    },
    {
      id: 10,
      head: 'Dr. Glen Alvin',
      text: "Medical Consultant",
      img: '/images/Dr-Glen-02-copy.jpg',
      link: "/dr-glen-alvin"
    },
  ]
  return (
    <div className="our-centers">
      <Studi0Banner txt='Advisory' img='bannerImage4' />
      <Container>
        <Row>
        {menters.map((each,i) => (
        <Col md={3} className='my-3'>
           <img src={each.img} alt='' className='AdvisoryImage' />
           <div className='vc_label'>{each.head}</div>
           <div>{each.text}</div>
        </Col>
      ))}
        </Row>
      </Container>
    </div>
  )
}

export default AdvisoryStudio
