import React,{ useState } from "react";
import { NavLink } from "react-router-dom";
import Studi0Banner from "./Studi0Banner";
import Studio1 from "./Studio1";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";

const HealthManagementStudio = () => {
  const Therapies = {
    head: "Health Management & Therapies",
    sHead:
      "Premium Regenerative Solutions Through Integrating Cellular Therapies, Biomedical Diagnostic And Therapeutic Devices With Advanced Health Programs.",
    para: "At European Wellness, we provide 4-stages of health management. Each stage allows us to provide personalised care and treatment for your individual health and needs. Under capable hands, you will be treated for the root causes of your condition and symptoms in a holistic framework of our integrative therapies.",
    img: "/images/health2.jpg",
  };

  const OptimumHealth = [
    { txt: "Diagnosis", img: "/images/diagnosis-icon.png" },
    { txt: "Detoxification", img: "/images/detoxification-icon.png" },
    { txt: "Repair", img: "/images/repair-icon.png" },
    { txt: "Rejuvenate", img: "/images/rejuvenation-icon.png" },
  ];

  const HealthRisks = [
    {
      head: "BIO MED SCAN",
      sHead: "New Definition Of Health Through Homeostasis",
    },
    {
      head: "OG SCAN",
      sHead: "Heavy Metal & Mineral Analysis",
    },
    {
      head: "BIO HORMONAL MODULATOR",
      sHead: "Hormonal Balance For Wellness",
    },
    {
      head: "BIO EAV",
      sHead: "Bioenergy Diagnosis For A Healthy Balance",
    },
    {
      head: "BIO MEDI PULSE BODY CHECK",
      sHead: "Most Advanced Total Health Assessment",
    },
    {
      head: "LIVE BLOOD ANALYSIS",
      sHead: "Real Time On The Spot Assessment",
    },
    {
      head: "BIOFEEDBACK (BIORESONANCE)",
      sHead: "Electromagnetic Analysis For Effective Disease Prevention",
    },
    {
      head: "FOOD INTOLERANCE TEST (ALCAT)",
      sHead: "The Hidden Factor In Healthy Lifestyle",
    },
  ];

  const DETOXIFICATION = [
    {
      head: "OZONE THERAPY",
      sHead: "Purify & Recharge",
    },
    {
      head: "COLON HYDROTHERAPY",
      sHead: "Flushing Out The Bad From Within",
    },
    {
      head: "PLAQX THERAPY",
      sHead: "Eliminate & Restore",
    },
    {
      head: "LASER BLOOD THERAPY",
      sHead: "Innovative Laser Technology for Health",
    },
    {
      head: "CHELATION",
      sHead: "Natural Removal Of Environmental Toxins",
    },
    
  ];

  const Supporting = [
    {
      head: "HBOT (HYPERBARIC OXYGEN THERAPY)",
      sHead: "Speeding Up Healing & Recovery",
    },
    {
      head: "IV THERAPY",
      sHead: "Supplementing & Revive",
    },
    {
      head: "OZONE DIALYSIS THERAPY",
      sHead: "",
    },
    {
      head: "SPLEEN THERAPY",
      sHead: "",
    },
    {
      head: "SCALA PLASMA THERAPY",
      sHead: "Quantum Healing & Natural Relaxation",
    },
    {
      head: "BIOIDENTICAL HORMONE THERAPY",
      sHead: "Achieving The Healthy Balance",
    },
    {
      head: "SYSTHEMIC HYPERTHERMIA",
      sHead: "",
    },
    {
      head: "PITUITARY GLAND THERAPY",
      sHead: "",
    },
  ];

  const Regenerative = [
    {
      head: "AUTISM/ASD",
      sHead: "Achieve Wellness Balance",
    },
    {
      head: "ARTHRITIS",
      sHead: "Unlock Flexibility",
    },
    {
      head: "KIDNEY DISEASE",
      sHead: "Healthy Equilibrium",
    },
    {
      head: "DOWN SYNDROME",
      sHead: "Improving Wellness",
    },
    {
      head: "LIVER DISEASE",
      sHead: "Purify The Essentials",
    },
    {
      head: "HEART DISEASE",
      sHead: "Health For Active Living",
    },
    {
      head: "ANTI-AGING & REJUVENATION",
      sHead: "Brings Out Your Younger Self",
    },
    {
      head: "INFERTILITY & SEXUAL DYSFUNCTION",
      sHead: "Renew Your Passion",
    },
    {
      head: "AESTHETIC THERAPIES",
      sHead: "Natural Beauty From Within",
    },
    
  ];

  const Diseases = [
    {
      id: 0,
      lable: "Liver Diseases",
      img: "https://www.youtube.com/embed/bBA75yoymHs",
      link: "",
      describtion:"Liver Diseases Explained – Causes, Symptoms, & Treatments",
    },
    {
      id: 1,
      lable: "Cardiovascular Diseases",
      img: "https://www.youtube.com/embed/AnElck_IHnY",
      link: "",
      describtion:"Cardiovascular Diseases Explained – Causes, Symptoms, & Treatments",
    },
    {
      id: 2,
      lable: "Kidney Diseases",
      img: "https://www.youtube.com/embed/OQ6Vm2h8J4s?feature=oembed",
      link: "",
      describtion:"Kidney Diseases Explained – Causes, Symptoms, & Treatments",
    },
    {
      id: 3,
      lable: "Endocrine Diseases",
      img: "https://www.youtube.com/embed/9h67ixc3IvU?feature=oembed",
      link: "",
      describtion:"Endocrine Diseases Explained – Causes, Symptoms, & Treatments",
    },
    {
      id: 4,
      lable: "Digestive Diseases",
      img: "https://www.youtube.com/embed/aL8Mpssmhgc?feature=oembed",
      link: "",
      describtion:"Digestive Diseases Explained – Causes, Symptoms, & Treatments",
    },
  ];

  const [diseasesData, setDiseasesData] = useState(Diseases[0])

  const handleTab = (i) => {
    console.log(i, "dcfsdfd")
    const data = Diseases.filter((each) => each.id === i);
    setDiseasesData(data[0]);
  };

  return (
    <div className="our-centers">
      <Studi0Banner txt="Health Management & Therapies" img="bannerImage2" />
      <Studio1 data={Therapies} />
      <Container className="mt-5">
        <Row>
          <div
            className="our_centers_text mb-5"
            style={{ color: "#2e3b97", marginLeft: "0%" }}
          >
            Four Pillars To Optimum Health
          </div>
          {OptimumHealth.map((each, i) => (
            <Col md={6} lg={3} key={i} className="vc_custom_1593502915043">
              <img src={each.img} alt="" className="h-100" />
              <div className="our_centers_small_text mt-3">{each.txt}</div>
            </Col>
          ))}
        </Row>
      </Container>
      <Container fluid className="vc_custom_diagnosis my-5">
        <Container>
          <Row>
            <Col md={2}>
              {" "}
              <img src="/images/diagnosis-icon.png" alt="" className="h-100" />
            </Col>
            <Col md={10}>
              <div className="para mb-2">STEP 1 - DIAGNOSIS</div>
              <div className="our_centers_heading">
                BIOMEDICAL DIAGNOSTIC & ASSESSMENTS
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col md={4}>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              Identifying Health Risks
            </div>
            <div className="para mb-2">
              To begin treatment, it is vital for us to determine the underlying
              cause of disease and the real conditional state of each patient.
              This is achieved through lengthy wellness assessment, non-invasive
              clinical onsite testing as well as offsite pathology and
              laboratory tests. Ours various instruments feature various
              complementary biomedical devices as well as conventional
              diagnostic tools.
            </div>
          </Col>
          <Col md={8}>
            <Row>
              {HealthRisks.map((each, i) => (
                <Col md={6} className="mb-2" key={i}>
                  <Row>
                    <Col xs={1}>
                      <AiFillCheckCircle size={21} color="#f5a81c" />
                    </Col>
                    <Col xs={11}>
                      <div className="our_centers_small_text mb-1">
                        {each.head}
                      </div>
                      <div className="para mb-2">{each.sHead}</div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="vc_custom_diagnosis my-5">
        <Container>
          <Row>
            <Col md={2}>
              {" "}
              <img
                src="/images/detoxification-icon.png"
                alt=""
                className="h-100"
              />
            </Col>
            <Col md={10}>
              <div className="para mb-2">STEP 2 - DETOXIFICATION</div>
              <div className="our_centers_heading">
                ELIMINATING IMPURITIES FOR A HEALTHY BALANCE
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col md={4}>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              Body Cleanse Naturally
            </div>
            <div className="para mb-2">
            Cleansing the body of heavy metals and other toxic substances has many benefits, including improving our energy levels and activating our metabolism, while boosting the efficiency of the body’s natural defences. A consistent detoxification process remedies the adverse effects of stress in our body and improves the action of Repair and Rejuvenate therapies. Our European Wellness’ detox protocol may include Phytotherapy, Chelation, Plaquex Infusion and Colon Hydrotherapy. Methods include as such:
            </div>
          </Col>
          <Col md={8}>
            <Row>
              {DETOXIFICATION.map((each, i) => (
                <Col md={6} className="mb-2" key={i}>
                  <Row>
                    <Col xs={1}>
                      <AiFillCheckCircle size={21} color="#f5a81c" />
                    </Col>
                    <Col xs={11}>
                      <div className="our_centers_small_text mb-1">
                        {each.head}
                      </div>
                      <div className="para mb-2">{each.sHead}</div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="vc_custom_diagnosis my-5">
        <Container>
          <Row>
            <Col md={2}>
              {" "}
              <img src="/images/repair-icon.png" alt="" className="h-100" />
            </Col>
            <Col md={10}>
              <div className="para mb-2">STEP 3 - REPAIR</div>
              <div className="our_centers_heading">
              INTEGRATIVE THERAPEUTIC TREATMENTS
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col md={4}>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              Supporting Health Modalities
            </div>
            <div className="para mb-2">
            Following the detoxification stage, a repair protocol is then implemented to help enhance the body’s natural defence and healing capacity for long-lasting wellness. EUROPEAN WELLNESS™ combines several methods to repair a patient’s immune system, strengthen the intestinal system, enhance blood circulation, and reinforce other physiological processes to restore the body’s internal balance.
            </div>
          </Col>
          <Col md={8}>
            <Row>
              {Supporting.map((each, i) => (
                <Col md={6} className="mb-2" key={i}>
                  <Row>
                    <Col xs={1}>
                      <AiFillCheckCircle size={21} color="#f5a81c" />
                    </Col>
                    <Col xs={11}>
                      <div className="our_centers_small_text mb-1">
                        {each.head}
                      </div>
                      <div className="para mb-2">{each.sHead}</div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className="vc_custom_diagnosis my-5">
        <Container>
          <Row>
            <Col md={2}>
              {" "}
              <img src="/images/rejuvenation-icon.png" alt="" className="h-100" />
            </Col>
            <Col md={10}>
              <div className="para mb-2">STEP 4 - REJUVENATION</div>
              <div className="our_centers_heading">
              BIOLOGICAL MEDICINE
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              Regenerative & Biomolecular Solutions
            </div>
            <div className="para mb-2">
            The stages of rejuvenation involve a range of individualized treatment prescribed to stimulate the body’s cellular renewal process ranging from oral rejuvenating supplements, bioactive anti-oxidants to the most advanced bio molecular peptide therapies. We employ cellular therapy that is specifically customised to a patients’ condition. This treatment is most intensive and as such will undergo strict screening to check the viability of such treatments on a patient.
            </div>
          </Col>
          </Row>
          <Col>
          <Row>
            <Col className="d-flex justify-content-between my-4">
            <div>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              CHRONIC DISEASE
            </div>
            <div className="para mb-2">Prevention & Management</div>
            </div>
            <div>
            <div
              className="our_centers_small_text mb-2"
              style={{ fontSize: "25px", fontWeight: "700" }}
            >
              REJUVENATION & ANTI-AGING
            </div>
            <div className="para mb-2">Defy Aging And Restore Natural Beauty</div>
            </div>
            </Col>
          </Row>
            <Row>
              {Regenerative.map((each, i) => (
                <Col md={4} className="mb-2" key={i}>
                  <Row>
                    <Col xs={1}>
                      <AiFillCheckCircle size={21} color="#f5a81c" />
                    </Col>
                    <Col xs={11}>
                      <div className="our_centers_small_text mb-1">
                        {each.head}
                      </div>
                      <div className="para mb-2">{each.sHead}</div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
        
      </Container>

      <Container className='mb-5'>
        <Row>
          <Col md={2}>
            {Diseases.map((ach, i) => (
              <div
                className={diseasesData.id === i ? "vc_tta_actv m-1" : "vc_tta-tab m-1"}
                key={i}
                onClick={() => handleTab(i)}
              >
                 {ach.lable}
              </div>
            ))}
          </Col>
          {Object.keys(diseasesData).length > 0 && (
            <Col md={6}>
              <div className="vc_label">{diseasesData.lable}</div>
              <iframe
                src={diseasesData.img}
                height="70%"
                width="100%"
                title="Iframe Example"
              ></iframe>
             
              <div className="para mb-4">{diseasesData.describtion}</div>
              

              <NavLink to="" className="vcx_button">
                Read More
              </NavLink>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default HealthManagementStudio;

