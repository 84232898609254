import React from 'react'
import { Row, Col, FloatingLabel, Form, Container, Card } from "react-bootstrap";
import State from './State'

const Investors = () => {

  const data = {
    title: "BENEFITS OF INVESTING IN STUDENT PROJECTS",
    data: "Invest with purpose at The Conscious State Investor Platform. Discover exceptional opportunities, engage with visionary entrepreneurs, and make a positive impact. Join us today for conscious investing that aligns profit with purpose.",
  }

  const investData = [
    {
      id: 1,
      img: "/images/10.png",
      head: "Purposeful Investing: ",
      text:"Discover and invest in ventures that align with your values and contribute to positive social and environmental impact.",
    },
    {
      id: 2,
      img: "/images/17.png",
      head: "Diverse Investment Options: ",
      text:"Choose from a range of investment opportunities tailored to your preferences, including equity investments, strategic partnerships, and more",
    },
    {
      id: 3,
      img: "/images/18.png",
      head: "Network and Knowledge Sharing: ",
      text:"Connect with industry experts, fellow investors, and thought leaders, expanding your network and gaining valuable insights. ",
    },
    {
      id: 4,
      img: "/images/15.png",
      head: "Exclusive Access: ",
      text:"Gain access to a curated selection of high-potential startups and innovative business opportunities before they reach the mainstream market. ",
    },
    {
      id: 5,
      img: "/images/14.png",
      head: "Align Profit with Purpose: ",
      text:"Invest in ventures that offer the potential for financial returns while more positive impact, allowing you to achieve both profit and purpose. ",
    },
    {
      id: 6,
      img: "/images/16.png",
      head: "Engage with Visionary Entrepreneurs: ",
      text:"Collaborate with passionate entrepreneurs who are driving change and actively shaping the future of their industries.",
    },
  ]

  return (
    <Container className="conservation margin-left-right">
      <State data={data} />
      <Row className='my-2'>
        <img className='Investorimage' src='/images/5-Ways-Investors-Can-Help-You-Grow-Your-Business.jpg' alt="" />
      </Row>
      <Row className='Entrepreneurs'>
        <h3>
        Welcome to The Conscious State Investor Platform, where purpose-driven
investing meets exceptional opportunities. Join our platform and unlock a
world of benefits:
        </h3>
        <Row>
        <Row className='icon-box'>
        {investData.map((each,i) => (
            <Col key={i} md={4} className=''>
              <div className='centerImage'>
                <div className='image'>
                <img src={each.img} alt='' className='icon-image' />
              </div>          
            <div className='centerHeader'>{each.head}</div>
            <div className='centerText'>{each.text}</div>
                </div>
             
          </Col>
          
        ))}
        </Row>
        </Row>
        {/* {investData.map((each,i) => (
          // <Col md={4} key={i}>
            <Card key={i} className='col-md-4'>
            <img src={each.img} alt='' className='centerImage' />
            <div className='centerHeader'>{each.head}</div>
            <div className='centerText'>{each.text}</div>
            
          </Card>
          
        ))} */}
      </Row>
    </Container>
  )
}

export default Investors
