import React from 'react'
import { BsArrowRight, BsClipboard2Pulse } from "react-icons/bs";
import { FaStethoscope } from "react-icons/fa";
import { BiDonateHeart } from "react-icons/bi";
import {IoIosArrowForward} from "react-icons/io"
import {CgClapperBoard} from 'react-icons/cg'
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './CstHealthcare.css'

const CstHealthcare = () => {
  return (
    <Container fluid className="healthcare">
      
      <video autoplay="" id='myVideo' playsinline="">
        <source src="https://cfvod.kaltura.com/pd/p/1825021/sp/182502100/serveFlavor/entryId/1_mjf14a1e/v/1/flavorId/1_jtaulkv8/name/a.mp4" type="video/mp4">
        </source>
      </video>
       
      <div class="content">
  <div className='banner-header'>Transformation is here</div>
  <NavLink to='/possible' className='more-btn'>Find out more <IoIosArrowForward /></NavLink>
  
  <button className='request-btn' onclick="myFunction()"><CgClapperBoard  className="me-2" />Request an appointment</button>
</div>
      <div className="row py-4 border-bottom padding-left-right">
        <div className="col-md-4 d-flex justify-content-center align-items-center border-end py-4">
          Request An Appointment <BsArrowRight className="ms-2" />
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center border-end py-4">
          Log in to patient account <BsArrowRight className="ms-2" />
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center py-4">
          Search diseases & conditions <BsArrowRight className="ms-2" />
        </div>
      </div>
      <div className="row my-5 padding-left-right">
        <div className="col-lg-4 text-center p-4 my-4">
          <h2>Why Choose</h2>
          <h2>CST Clinic?</h2>
          <button className="btn btn-primary my-2">
            Learn more about Sir Kalam's Humanitarian Hospitals
          </button>
        </div>
        <div className="col-lg-4 p-4 my-4 d-flex flex-column">
          <div
            style={{ border: "1px solid black", width: "80%" }}
            className="my-4"
          ></div>
          <FaStethoscope size="2rem" className="my-2" />
          <h4 className="my-2">More experience</h4>
          <p className="my-2">
            The million patients we treat each year prepares us to treat the one
            who matters most — you.
          </p>
        </div>
        <div className="col-lg-4 p-4 my-4 d-flex flex-column">
          <div
            style={{ border: "1px solid black", width: "80%" }}
            className="my-4"
          ></div>
          <BsClipboard2Pulse size="2rem" className="my-2" />
          <h4 className="my-2">The right answers</h4>
          <p className="my-2">
            Count on our experts to deliver an accurate diagnosis and the right
            plan for you the first time.
          </p>
        </div>
      </div>
      <div className="row my-5 padding-left-right">
        <div className="col-lg-4 text-center p-4 my-4"></div>
        <div className="col-lg-4 p-4 my-4 d-flex flex-column">
          <div
            style={{ border: "1px solid black", width: "80%" }}
            className="my-4"
          ></div>
          <BiDonateHeart size="2rem" className="my-2" />
          <h4 className="my-2">You come first</h4>
          <p className="my-2">
            Treatment at Sir Kalam's Humanitarian Hospitals is a truly human experience. You're cared
            for as a person first.
          </p>
        </div>
        <div className="col-lg-4 p-4 my-4 d-none d-lg-flex flex-column">
          <div
            style={{ border: "1px solid black", width: "80%" }}
            className="my-4"
          ></div>
          <FaStethoscope size="2rem" className="my-2" />
          <h4 className="my-2">Innovation with impact</h4>
          <p className="my-2">
            All of our patient care, education and research are driven to make
            discoveries that can help heal you.
          </p>
        </div>
      </div>
      <div className="row padding-left-right">
        <div className="col-lg-6 p-5 no_1_heading">
          <div className="my-4 fs-1 heading">
            The No. 1
            <br />
            hospital
          </div>
          <p className="my-4 fs-5">
            Sir Kalam's Humanitarian Hospitals is the No. 1 hospital overall.
          </p>
        </div>
        <div className="col-lg-6 p-0 m-md-0">
          <div style={{ backgroundColor: "#949494" }} className="py-5 px-4 ">
            <h2 className="heading">International services at Sir Kalam's Humanitarian Hospitals</h2>
            <p className="my-4">The trusted leader in healthcare worldwide.</p>
          </div>
          <div className="col d-lg-flex">
            <div
              style={{ backgroundColor: "#54c0c0" }}
              className="p-5 col-lg-6"
            >
              <h2 className="heading">Give now</h2>
              <p>Your generous support leads to breakthrough results.</p>
            </div>
            <div
            style={{background: '#003da5', color: '#fff'}}
              className="p-5 col-lg-6"
            >
              <div className="Relentless heading1">Relentless resides here.</div>
              <p>
                We are changing the very definition of possible. And we’re doing
                it <strong>right here.</strong> <strong>Right now</strong> Meet
                the physicians and teams united by an unrelenting purpose to
                change the future of health care. Forever.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CstHealthcare
