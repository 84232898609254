import React, { useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import { IoIosArrowForward } from "react-icons/io";
import { BsBoxArrowUpRight } from "react-icons/bs";
import Studi0Banner from "./Studi0Banner";
import MapStudio from "./MapStudio";
import Studio1 from "./Studio1"
import samplePDF from "../components/2022-Corporate-Profile-European-Wellness-Biomedical-Group.pdf";

const StdioAboutUs = () => {
  const book = useRef();
  const width = 100;
  const height = 224;

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const Page = React.forwardRef(({ pageNumber }, ref) => {
    return (
      <div ref={ref} className="PdfPage">
        <ReactPdfPage pageNumber={pageNumber} width={width} height={345} />
      </div>
    );
  });

  const Showcase = {
    head: "The Showcase",
    sHead: "European Wellness Showcase™",
    para : "This program showcases specific equipment and devices that are unique to our biological wellness paradigm. They have been brought together and assembled as the EUROPEAN WELLNESS SHOWCASE™ where partners and potential licensees can witness a virtual CENTER in a practical sense. This removes the challenge of experiencing the concept without interfering with client treatments and therapies.",
    frame:"https://www.youtube.com/embed/2A9tBxhz0ZU?feature=oembed",
  }

  const Research = {
    head: "Research & Development",
    sHead: "Baden Research & Testing Lab",
    para : "An advanced biotechnology research arm of European Wellness Biomedical Group, Baden Research and Testing Lab seeks to push the boundaries of regenerative medicine, cellular therapy, and stem cell research for a better and healthier world. Its world-class facilities and team of experienced professionals allow Baden Research and Testing Lab to be a leading provider in bioregenerative research and development, laboratory consultation and training services, and product testing and analysis.",
    img:"/images/baden.jpg",
    button: 'More information about Baden Research & Testing Lab',
    link: '/european-wellness-centers'
  }


  return (
    <div className="our-centers">
      <Studi0Banner txt="About Us" img='bannerImage1' />
      <Container>
        <Row>
          <Col>
            <div className="our_centers_heading">The Company</div>
            <div className="our_centers_small_text">
              European Wellness Biomedical Group
            </div>
            <div className="para mb-2">
              <strong>European Wellness Biomedical Group (EW Group)</strong> is
              an international multi-award-winning European group renowned for
              its pioneering developments in precursor stem cell therapeutics,
              immunomodulation, biological and synthetic peptides,
              nutraceuticals, cosmeceuticals and biological regenerative
              medicine. Headed by esteemed scientist and stem cell research
              pioneer{" "}
              <NavLink to="/advisory/prof-dr-mike-chan/">
                Prof. Dato’ Sri Dr. Mike Chan
              </NavLink>
              , the Group owns over 20 international patents and has contributed
              over 30+ books and over 70+ publications in science and medicine
              in the US and UK. The Group was founded in the early 90’s through
              the culmination of comprehensive cell therapeutics research
              efforts that span across multiple nations including Switzerland,
              Germany, Russia, UK and Austria. Today, the Group’s portfolio
              comprises multinational business divisions that collectively
              operate in the emerging fields of biological regenerative
              medicine. These divisions include research and development,
              bio-manufacturing, training and education, anti-aging centers,
              private labelling for established companies worldwide, and product
              distribution to consumers via licensed practitioners across 80
              countries. The Group also owns a growing network of over 30+
              internationally-accredited hospitals, medical centres specialising
              in biological and regenerative medicine, and luxury Youth
              Restoration and Wellness Centres globally. It has established
              itself as one of the top biomedical groups in the world, and is
              currently headquartered in Germany, Greece, UK, Switzerland and
              Kota Kinabalu, Malaysia (Asia Pacific), with supporting centres
              and research facilities located strategically around the world.
            </div>
            <div className="para mb-2">
              EW Group owns the <strong>EUROPEAN WELLNESS™ brand</strong>, which
              is a holistic wellness and rejuvenation concept based on the
              scientifically-backed knowledge and effectiveness of Swiss and
              German biological medicine and bio-molecular therapy. Its premium
              accreditation, the <strong>EUROPEAN WELLNESS CENTRE (EWC)</strong>
              , is applied to licensed centres and their practitioners who meet
              the standards necessary to integrate European Wellness’s advanced
              treatment protocols and methodology. These centres – currently
              located in multiple sites worldwide including Greece (Santorini,
              Athens), Switzerland (Lugano), Germany, Thailand, Bangladesh,
              China, Malaysia, and the Philippines, to name a few – offer
              patients holistic treatment for anti-aging, age reversal,
              aesthetics, and whole-life disease and health management with an
              emphasis on delivering premium services in a secure,
              ultra-exclusive, and private setting. EWC’s team of practitioners
              and service providers have earned a reputation as skilled,
              conscious and respectful professionals, well-suited to upholding
              their position as ambassadors of a paradigm approach to wellness
              and improved lifestyle.
            </div>
            <div className="para mb-2">
              The Group is also highly invested in the education of current and
              future wellness and healthcare professionals. In addition to
              organising international seminars and workshops throughout the
              world, European Wellness’s academic pursuits are supported by its
              educational arm, <strong>EUROPEAN WELLNESS ACADEMY (EWA)</strong>.
              This institution provides independent certification for European
              Wellness Center practitioners, channels funds to aid further
              research on behalf of the Group, and lobbies the appropriate
              authorities in multiple jurisdictions to facilitate change, being
              the platform for the group’s sustainability, corporate social
              responsibility and environmental and social governance programs.
              These programs aim to promote the benefits of biological wellness,
              challenge the dominant paradigms of the widespread use of
              pharmaceuticals, and support and treat underprivileged patients
              with chronic conditions and diseases deemed ‘untreatable’ by
              western medicine. The Academy’s services are currently delivered
              through advanced training facilities in Germany, Switzerland,
              Greece, and Malaysia, at the heart of the market where greatest
              growth is expected.
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              // position: "relative",
              //  overflow: "hidden",
              backgroundColor: "rgb(119, 119, 119)",
              //  display: 'flex',
              //  justifyContent: 'center',
              //  padding: 'auto 0px',
              //alignItems: 'center',
              // objectFit: 'contain',
              //minHeight: 100,
              // minWidth: 1200,
              //height: 500,
            }}
          >
            {/* <button onClick={() =>
                    book.current.pageFlip().flipNext()}>Next page</button> */}
            <Document file={samplePDF}>
              <HTMLFlipBook width={width} height={height} ref={book}>
                <Page pageNumber={1} />
                <Page pageNumber={2} />
                <Page pageNumber={3} />
                <Page pageNumber={4} />
                <Page pageNumber={5} />

                <Page pageNumber={6} />

                <Page pageNumber={7} />

                <Page pageNumber={8} />

                <Page pageNumber={9} />

                <Page pageNumber={10} />

                <Page pageNumber={11} />

                <Page pageNumber={12} />

                <Page pageNumber={13} />

                <Page pageNumber={14} />

                <Page pageNumber={15} />

                <Page pageNumber={16} />

                <Page pageNumber={17} />

                <Page pageNumber={18} />

                <Page pageNumber={19} />

                <Page pageNumber={20} />

                <Page pageNumber={21} />

                <Page pageNumber={22} />

                <Page pageNumber={23} />

                <Page pageNumber={24} />

                <Page pageNumber={25} />

                <Page pageNumber={26} />

                <Page pageNumber={27} />

                <Page pageNumber={28} />

                <Page pageNumber={29} />

                <Page pageNumber={30} />

                <Page pageNumber={31} />

                <Page pageNumber={32} />

                <Page pageNumber={33} />

                <Page pageNumber={34} />

                <Page pageNumber={35} />

                <Page pageNumber={36} />

                <Page pageNumber={37} />

                <Page pageNumber={38} />

                <Page pageNumber={39} />

                <Page pageNumber={40} />

                <Page pageNumber={41} />

                <Page pageNumber={42} />

                <Page pageNumber={43} />

                <Page pageNumber={44} />

                <Page pageNumber={45} />

                <Page pageNumber={46} />

                <Page pageNumber={47} />

                <Page pageNumber={48} />

                <Page pageNumber={49} />

                <Page pageNumber={50} />

                <Page pageNumber={51} />

                <Page pageNumber={52} />

                <Page pageNumber={53} />

                <Page pageNumber={54} />

                <Page pageNumber={55} />

                <Page pageNumber={56} />

                <Page pageNumber={57} />

                <Page pageNumber={58} />

                <Page pageNumber={59} />

                <Page pageNumber={60} />

                <Page pageNumber={61} />

                <Page pageNumber={62} />

                <Page pageNumber={63} />

                <Page pageNumber={64} />

                <Page pageNumber={65} />

                <Page pageNumber={66} />
              </HTMLFlipBook>
            </Document>
          </Col>
        </Row>
      </Container>
      <Container fluid className="vc_custom_1663055695134 my-5">
        <Container>
          <Row>
            <Col md={6}>
              <iframe
                src="https://www.youtube.com/embed/aJlL9TVrYbU?feature=oembed"
                height="100%"
                width="100%"
                title="Iframe Example"
              ></iframe>
            </Col>
            <Col md={6}>
              <div className="our_centers_heading" style={{ color: "#fff" }}>
                The Brand
              </div>
              <div className="our_centers_small_text" style={{ color: "#fff" }}>
                European Wellness™
              </div>
              <div className="para mb-2" style={{ color: "#fff" }}>
                EUROPEAN WELLNESS™ is a holistic wellness and rejuvenation
                concept based on the knowledge and effectiveness of Swiss
                Biological Medicine and bio-molecular therapy. We, at European
                Wellness offer patients holistic treatment for anti-aging,
                aesthetic and whole-life disease management. Our associates and
                business partners adhere to best international financial
                accounting practice and reporting, business methodologies,
                national regulation compliance and governance. Our team is
                conscious and respectful, and we uphold a position as
                ambassadors of a paradigm approach to wellness and improved
                lifestyle.
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col md={6} className="home">
            <div className="our_centers_heading">The Wellness Center</div>
            <div className="our_centers_small_text">
              European Wellness Center™
            </div>
            <div className="para">
              EUROPEAN WELLNESS CENTER™ is the premium accreditation – an earned
              badge of endorsement – applied to locally, licensed centers and
              their practitioners who meet the standards necessary to apply the
              EUROPEAN WELLNESS™ treatment protocols and methodology in a local
              market. Its vision is to integrate personalised holistic wellness
              treatment services customised for innate healing using the
              principles of Swiss Biological Medicine, in a secure,
              ultra-exclusive and private setting. The initial centers that
              operate and being progressively introduced to market are wholly
              owned by EWC International.
              <br />
              The flagship center in Lugano, Switzerland operates under a
              national brand format, SWISS WELLNESS CENTER but delivers the same
              protocols, therapies and level of service. In Germany, the group
              operates as German Medical Center, Frankfurt through a partnership
              with the The Siebenhüner practice in the center of the Advanced
              Medical Clinic in Frankfurt, which has been practicing holistic
              integrative medicine for 35 years.
            </div>
            <NavLink to="/european-wellness-centers" className="bioButton mt-2">
              Visit Our Wellness Centers <IoIosArrowForward />{" "}
            </NavLink>
          </Col>
          <Col md={6} className="wpb_column vc_column_container">
            <MapStudio />
          </Col>
        </Row>
      </Container>
      <Container fluid className="vc_custom_1663055695134 my-5">
        <Container>
          <Row>
            <Col md={6}>
              <img
                src="/images/about-items6.png"
                className="w-100 h-100"
                alt=""
              />
            </Col>
            <Col md={6} className="home">
              <div className="our_centers_heading" style={{ color: "#fff" }}>
                The Academy
              </div>
              <div className="our_centers_small_text" style={{ color: "#fff" }}>
                European Wellness Academy™
              </div>
              <div className="para mb-2" style={{ color: "#fff" }}>
                The EUROPEAN WELLNESS ACADEMY™ is the education, training and
                empowerment organization to appropriately support and serve the
                group. It is to be the based on which the global aspirations
                grow and organise the education and independent certification
                for EWC practitioners. It is also the institution that funds and
                aids further research on behalf of the group and lobbies
                appropriate authorities in multiple jurisdictions to facilitate
                change, being the platform for the group’s sustainability,
                corporate social responsibility and environmental and social
                governance programs. The intention of these programs is to
                promote the benefits of biological wellness, challenge the
                dominant paradigms of the widespread use of pharmaceuticals, and
                support and treat underprivileged children with chronic
                conditions and disease deemed ‘untreatable’ by western medicine.
              </div>
              <div className="para mb-4" style={{ color: "#fff" }}>
                The Academy’s services will first be delivered through a custom
                built training facility in Malaysia, at the heart of the market
                where greatest growth is expected.&nbsp; Flights are shorter and
                costs far less for practitioners, nurses, technicians and
                managers to conveniently attend on site, in center training.
              </div>
              <NavLink
                to="/european-wellness-centers"
                className="bioButton mt-2"
              >
                Visit EWA Official Site <BsBoxArrowUpRight />{" "}
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>
      <Studio1 data={Showcase} />
      <Container fluid className="vc_custom_1663055695134 my-5">
        <Container>
          <Row>
            <Col md={6}>
              <img
                src="/images/biopep-figure01.jpg"
                className="w-100 h-100"
                alt=""
              />
            </Col>
            <Col md={6} className="home">
              <div className="our_centers_heading" style={{ color: "#fff" }}>
                Research & Development
              </div>
              <div className="our_centers_small_text" style={{ color: "#fff" }}>
                Bio Peptides LLC (BioPep)
              </div>
              <div className="para mb-2" style={{ color: "#fff" }}>
                BioPep is a research and development subsidiary of European
                Wellness Biomedical Group focused on researching and developing
                safe, science-driven treatments – specifically, organopeptide
                therapeutics that rejuvenate and heal – with the goal of
                achieving better health for everyone. High quality science is a
                collaborative effort – BioPep partners with top-tier, global
                scientific institutions, university research teams, and
                manufacturing facilities to provide these life-changing
                therapeutics.
              </div>
              <div className="para mb-4" style={{ color: "#fff" }}>
                BioPep uses organopeptides manufactured at a US FDA-registered
                cGMP facility in Golden, Colorado (Vitro Biopharma) to ensure
                that all of its developed organopeptide products are
                pathogen-free and safe.
              </div>
              <NavLink
                to="/european-wellness-centers"
                className="bioButton mt-2"
              >
                More information about BioPep <BsBoxArrowUpRight />{" "}
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>
      <Studio1 data={Research} />
    
    </div>
  );
};

export default StdioAboutUs;
