import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { HiChevronRight } from "react-icons/hi";

const SuccessStories = () => {
  return (
    <Container fluid className="SuccessStories">
      <Row>
        <Col md={4} className="ps-5">
          <div className="bioHeader">Success Stories</div>
          <div className="centerText my-5" style={{color:'#616161'}}>
            Listen to what our happy clients have to say.
          </div>
          <button type="button my-3" className="bioButton Diabutton">
            Find out more success stories
            <HiChevronRight /> 
          </button>
        </Col>
       
        <Col md={4} style={{marginTop: "60px"}}>
          <Card className="storiesCard">
            <a
              href="https://www.youtube.com/watch?v=WiFNzwrDS3s"
              rel="noreferrer"
            >
              <img
                className="storiesImage"
                src="/images/christy-chung (1).jpg"
                alt=""
              />
            </a>
            <div className="bioHeader">Christy Chung</div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
              Renowned Popular Celebrity/Actress{" "}
            </div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
              Hong Kong/China{" "}
            </div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
              Loyal VVIP client of European Wellness{" "}
            </div>
            <div className="centerText my-2" style={{color:'#616161'}}>
              
              Christy Chung once again share her wellness journey toward younger and healthier with EWC
            </div>
          </Card>
        </Col>
        <Col md={4} style={{marginTop: "20px"}}>
          <Card className="storiesCard">
            <a
              href="https://www.youtube.com/watch?v=QZqtOWERyqc"
              rel="noreferrer"
            >
              <img
                className="storiesImage"
                src="/images/shawn-zhang.jpg"
                alt=""
              />
            </a>
            <div className="bioHeader">Shawn Zhang</div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
            Popular Reality Show Celebrity{" "}
            </div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
            Actor/Singer/Composer{" "}
            </div>
            <div className="centerText">
            <HiChevronRight color="#f5a81c" />
            Loyal VVIP client of European Wellness{" "}
            </div>
            <div className="centerText my-2" style={{color:'#616161'}}>
              
            Shawn Zhang strengthen his belief on cell therapy after his wellness journey experience with EWC
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SuccessStories;
