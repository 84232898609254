import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Trust from "./components/Trust";
// import School from "./components/School";
import Layout from "./components/layout/Layout";
import SchoolAcademy from "./components/SchoolAcademy";
import Media from "./components/Media";
import TechSolutions from "./components/TechSolutions";
import ProjectsAndEvents from "./components/ProjectsAndEvents";
import Conservation from "./Conservation";
import "./App.css";
import School1 from "./components/School1";
import Faqs from "./components/Faqs";
import Aboutus from './components/StaticPages/Aboutus'
import Contributor from './components/StaticPages/Contributor'
import Entrepreneur from './components/StaticPages/Entrepreneur'
import Investor from './components/StaticPages/Investor'
import Member from './components/StaticPages/Member'
import Partners from './components/StaticPages/Partners'
import PrivacyPolicy from './components/StaticPages/PrivacyPolicy'
import CstHealthcare from './components/CstHealthcare'
import CstStudio from './components/CstStudio'
import Mentors from './components/Mentors'
import Investors from './view/Investors'
import Entrepreneurs from './view/Entrepreneurs'
import Possible from './components/Possible'
import OurCenters from "./view/OurCenters";
import StdioAboutUs from "./components/StdioAboutUs"
import HealthManagementStudio from './components/HealthManagementStudio'
import HealthDiagnosesStudio from './components/HealthDiagnosesStudio'
import AdvisoryStudio from './components/AdvisoryStudio'

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" exact element={<Conservation />} />
          <Route path="/faqs" exact element={<Faqs />} />
          <Route path="/trust" exact element={<Trust />} />
          <Route path="/school" exact element={<School1 />} />
          <Route path="/schoolacademy" exact element={<SchoolAcademy />} />
          <Route path="/media" exact element={<Media />} />
          <Route path="/techsolutions" exact element={<TechSolutions />} />
          <Route path="/healthcare" exact element={<CstHealthcare />} />
          <Route path="/possible" exact element={<Possible />} />
          <Route path="/StdioAboutUs" exact element={<StdioAboutUs />} />
          <Route path="/european-wellness-centers" exact element={<OurCenters />} />
          <Route path="/health-management-therapies" exact element={<HealthManagementStudio />} />
          <Route path="/health-diagnose" exact element={<HealthDiagnosesStudio />} />
          <Route path="/advisory" exact element={<AdvisoryStudio />} />
          <Route
            path="/projectsandevents"
            exact
            element={<ProjectsAndEvents />}
          />
          <Route path="/studio" exact element={<CstStudio />} />
         <Route path="/mentors" exact element={<Mentors />} />
           <Route path="/investors" exact element={<Investors />} />
           <Route path="/entrepreneurs" exact element={<Entrepreneurs />} />
          <Route path="/about-us" exact element={<Aboutus />} />
     
     
     
      <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
      
      <Route path="/becomea-a-member" exact element={<Member />} />
     
      <Route path="/became-a-contributor" exact element={<Contributor />} />
     
      <Route to="/become-an-investor" exact element={<Investor />} />
      
      <Route to="/become-an-entrepreneur" exact element={<Entrepreneur />} />
      
      <Route to="/site-partners" exact element={<Partners />} />
      
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
