import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {FaLongArrowAltRight} from 'react-icons/fa'

const Possible = () => {
  return (
    <Container fluid className='possible'>
      <Row>
        <Col className='component-hero'>
        <img src='/images/campaign.svg' alt="" className='picture' />
        </Col>
      </Row>
      <div className='two-gap'></div>
      <Container>
        <Row>
            <Col md={9} className='vertical-line'>
                <div className='head'>This is where the unbelievable happens.</div>
                <div className='para'>At Mayo Clinic, we are doing what’s never been done. From groundbreaking treatments to personalized care enhanced through innovations like AI and 3D printing, we have the skills, the people, and the ideas to transform medicine. Health care will never be the same.</div>
            </Col>
            <Col md={3}></Col>
        </Row>
      </Container>
      <div className='two-gap'></div>
      <Row className='component-hero'>
        {/* <ul>
            <li>
            <div className='head1'>See if our team of experts can help you.</div>
       <div className='para1'>Request an appointment <FaLongArrowAltRight /></div>
            </li>
            <li className='vertical-line'></li>
            <li>
            <div className='head1'>Get the latest news about our groundbreaking innovations.</div>
        <div className='para1'>Join our email list <FaLongArrowAltRight /></div>
            </li>
        </ul> */}
        
        <Col md={5} className=''>
       <div className='head1'>See if our team of experts can help you.</div>
       <div><span className='para1'>Request an appointment </span><FaLongArrowAltRight color='#fff' /></div>
        </Col>
        <Col md={1} className='vertical-line2'></Col>
        <Col md={5} className=''>
        <div className='head1'>Get the latest news about our groundbreaking innovations.</div>
        <div><span className='para1'>Join our email list</span> <FaLongArrowAltRight color='#fff' /></div>
        </Col>
       
      </Row>
      <div className='two-gap'></div>
      <Container>
        <Row>
          <Col md={4} >
            <div className='con-find-out' style={{backgroundColor: '#0057B8'}}>
            <em>Find out more:</em>
            <div className='con-text'>How diseases are becoming more  
            <strong> curable</strong> through the power of data.</div>
            <div className='long-arrow'><FaLongArrowAltRight /></div>
            </div>
            
          </Col>
          <Col md={4}>
          <div className='con-find-out' style={{backgroundColor: '#64CCC9'}}>
            <em>Find out more:</em>
            <div className='con-text'>How making details more   
            <strong> seeable</strong>  bring a better diagnosis.</div>
            <div className='long-arrow'><FaLongArrowAltRight /></div>
            </div>
          </Col>
          <Col md={4}>
          <div className='con-find-out' style={{backgroundColor: '#FFC845'}}>
            <em>Find out more:</em>
            <div className='con-text'>How complex surgeries are made more   
            <strong> predictable</strong>  through technology.</div>
            <div className='long-arrow'><FaLongArrowAltRight /></div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Possible
