import React from 'react'
import { Row, Container, Card, Col } from "react-bootstrap";
import State from './State'
import "./Entrepreneurs.css"

const Entrepreneurs = () => {
    const data = {
        title: "BENEFITS OF ENTREPRENEURS IN STUDENT PROJECTS",
        data: "Welcome to The Conscious State, the premier platform for entrepreneurs seeking to turn their ideas into reality. We connect innovative minds with conscious investors who are passionate about supporting purpose-driven businesses. Showcase your business idea, present your pitch deck, and unlock opportunities for growth and investment. Join our empowering community and make a meaningful impact in the world. Together, let's create a brighter future at The Conscious State.",
      }
    
      const EntrepreneursData = [
        {
          id: 1,
          img: "/images/7.png",
          head: " ",
          text:"Access to conscious investors",
        },
        {
          id: 2,
          img: "/images/8.png",
          head: " ",
          text:"Showcase your ideas and pitch decks",
        },
        {
          id: 3,
          img: "/images/9.png",
          head: " ",
          text:"Increase visibility and credibility ",
        },
        {
          id: 4,
          img: "/images/11.png",
          head: " ",
          text:"Network and collaborate with like-minded entrepreneurs ",
        },
        {
          id: 5,
          img: "/images/12.png",
          head: " ",
          text:"Access additional resources and support ",
        },
        {
          id: 6,
          img: "/images/13.png",
          head: " ",
          text:"Transparent and secure investment process",
        },
      ]

  return (
    <Container className="conservation margin-left-right">
      <State data={data} />
      <Row className='my-2'>
        <img className='Investorimage' src='/images/pexels-william-fortunato-6140676.jpg' alt="" />
      </Row>
      <Row className='Entrepreneurs'>
        <h3>
        At The Conscious State, we are committed to empowering entrepreneurs and helping
them thrive on their journey. By joining our platform, you can enjoy a range of benefits:
        </h3>
        <Row className='icon-box'>
        {EntrepreneursData.map((each,i) => (
            <Col key={i} md={4} className=''>
              <div className='centerImage'>
                <div className='image'>
                <img src={each.img} alt='' className='icon-image' />
              </div>          
            <div className='centerHeader'>{each.head}</div>
            <div className='centerText'>{each.text}</div>
                </div>
             
          </Col>
          
        ))}
        </Row>
       
      </Row>
    </Container>
  )
}

export default Entrepreneurs
