import React from 'react'

const Trust = () => {
    return(
        <div className=''>
            <div className=''>Trust</div>
            </div>
    )
}

export default Trust