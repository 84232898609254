import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const About = ({data, title}) => {

  return (
    <Container className="video-container margin-left-right">
      <h2 className="main-titles title">{title}</h2>
        {data.map((each,i) => 
        <Row className="my-4" key={i}> 
      <Col md={4}>
        <img className='menterimage' style={{height: '50vh'}}
        src={each.img} alt='' />
        <div className='text-center'>
        <div>{each.name}</div>
        <div>{each.designation}</div>
        <div>{each.specialition}</div>
        </div>
        
      </Col>
        <Col md={8} className='p-5'>
        
        <p>{each.content}</p>
        <p>{each.content1}</p>
        </Col>
        </Row>)}
      
        
    
      
    </Container>
  )
}

export default About
