import React from "react";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import { FiFacebook, FiTwitter } from "react-icons/fi";
import {  FaLinkedin } from "react-icons/fa";

const ConnectState = () => {
  return (
    <div className="nav">
      <div className="my-container">
        <div style={{ color: "#fff" }}>
          <span>Connect with the Conscious State</span>
        </div>
        <div
          style={{
            display: "flex",
            marginInline: "10px",
          }}
        >
          <a
            className="mx-2"  rel="noreferrer"
            href="https://www.instagram.com/the.conscious.state/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
          >
            <BsInstagram color="#fff" className="logos" />
          </a>
          <a
            className="mx-2" rel="noreferrer"
            href="https://www.facebook.com/people/The-Conscious-State/100091764465956/?mibextid=ZbWKwL"
          >
            <FiFacebook color="#fff" className="logos" />
          </a>
          <a className="mx-2" rel="noreferrer" href="https://twitter.com/Theconsciousst3">
            <FiTwitter color="#fff" className="logos" />
          </a>
          <a className="mx-2"  rel="noreferrer" href="https://www.youtube.com/@theconsciousstate6774">
            <BsYoutube color="#fff" className="logos" />
          </a>
          <a
            className="mx-2" rel="noreferrer"
            href="https://www.linkedin.com/in/the-conscious-state-4b8716271/"
          >
            <FaLinkedin color="#fff" className="logos" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConnectState;
